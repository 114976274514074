define("lbf/components/legend/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="flex-column align-items-start">
    <span class="text-muted">{{@config.title}}</span>
      <ul>
        {{#each @config.legend as |legendConfig|}}
          <Legend::Item @config={{legendConfig}}></Legend::Item>
        {{/each}}
      </ul>
  </li>
  
  */
  {
    "id": "7VOtjnCM",
    "block": "[[[10,\"li\"],[14,0,\"flex-column align-items-start\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"text-muted\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"legend\"]]],null]],null],null,[[[1,\"        \"],[8,[39,2],null,[[\"@config\"],[[30,2]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@config\",\"legendConfig\"],false,[\"each\",\"-track-array\",\"legend/item\"]]",
    "moduleName": "lbf/components/legend/group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagname: ''
  }));

  _exports.default = _default;
});