define("lbf/components/afdeling-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isFavorite: Ember.computed('state.favoriteAfdelinger.[]', 'afdeling.lbfNummer', function () {
      return this.state.favoriteAfdelinger.indexOf(this.afdeling.lbfNummer) !== -1;
    }),
    antalBoliger: Ember.computed('afdeling.byggerier.[]', function () {
      var antal = 0;

      if (this.afdeling.byggerier) {
        for (var i = 0; i < this.afdeling.byggerier.length; i++) {
          var byggeri = this.afdeling.byggerier[i];

          if (byggeri.boliger) {
            for (var j = 0; j < byggeri.boliger.length; j++) {
              var bolig = byggeri.boliger[j];
              antal += bolig.lavtByggeriAntal;
              antal += bolig.etageByggeriAntal;
              antal += bolig.blandetByggeriAntal;
            }
          }
        }
      }

      return antal;
    }),
    actions: {
      showOrganisation: function showOrganisation(nummer) {
        this.state.set('showOrganisation', nummer);
      },
      toggleFavorites: function toggleFavorites() {
        if (this.state.favoriteAfdelinger.indexOf(this.afdeling.lbfNummer) === -1) {
          this.state.favoriteAfdelinger.pushObject(this.afdeling.lbfNummer);
        } else {
          this.state.favoriteAfdelinger.removeObject(this.afdeling.lbfNummer);
        }

        this.state.save();
      }
    }
  });

  _exports.default = _default;
});