define("lbf/templates/components/modal/modal-col-start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CKvv5gT3",
    "block": "[[[10,\"header\"],[14,0,\"row l-modal__subheader g-0 mx-0\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"h5 mb-0\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null]],[\"@heading\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "lbf/templates/components/modal/modal-col-start.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});