define("lbf/templates/noaccess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b27vEeFp",
    "block": "[[[10,0],[14,0,\"almen-gis-front\"],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"l-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"login-bar\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"https://lbf.dk/\"],[14,0,\"login-bar-link login-bar-logo-box\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/img/lbf-logo-white.png\"],[14,\"alt\",\"LBF\"],[14,0,\"login-bar-lbf-logo\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,3],[14,6,\"https://lbf.dk/selvbetjeninger/\"],[14,0,\"login-bar-link login-bar-user\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"LBFs selvbetjeninger\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"main-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"logo\"],[12],[1,\"\\n            \"],[1,[28,[35,0],null,[[\"name\"],[\"mainlogo\"]]]],[1,\"\\n            \"],[10,\"h1\"],[14,0,\"service-name\"],[12],[1,\"AlmenGIS \"],[1,[52,[33,2],\"Offentlig\"]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"main-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"intro-view\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"editor-content\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,\"Ingen adgang\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"Du har desværre ikke rettigheder til at benytte denne løsning. Kontakt din lokale administrator (LRA-ansvarlige) i kommunen/boligorganisationen for at få tildelt rettigheden. Ved fejl kontakt Landsbyggefonden.\"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"          \"],[10,2],[14,0,\"intro-view-buttoncontainer\"],[12],[11,\"button\"],[24,0,\"button\"],[24,4,\"button\"],[4,[38,4],[[30,0],\"signout\"],null],[12],[1,\"Log ud\"],[13],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"icons\",\"if\",\"isPublic\",\"showLogout\",\"action\"]]",
    "moduleName": "lbf/templates/noaccess.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});