define("lbf/components/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-avatar'],
    classNameBindings: ['typeClassName', 'colorClassName'],
    typeClassName: Ember.computed('type', function () {
      // Type tilføj klasse afhængigt af indtastet værdi
      if (this.type == "icon") {
        return 'c-avatar--icon';
      } else if (this.type == "text") {
        return 'c-avatar--text';
      }

      return '';
    }),
    colorClassName: Ember.computed('color', function () {
      return "bg-".concat(this.color);
    })
  });

  _exports.default = _default;
});