define("lbf/config/data_single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.afdelinger = void 0;
  var afdelinger = [{
    "AfdelingId": 5387,
    "BkAfdelingId": "CA5D7C6E-1A8E-409A-B33D-952AEC4DE35B",
    "BkRecordKey": "R8B6760680DE0F9A2C1256E7800298280",
    "OrganisationId": 1,
    "Nummer": 12,
    "Navn": "CAMA",
    "StatusKode": 1,
    "KommuneKodeFoerKommunalreform": 479,
    "Bemaerkning": null,
    "VirkningFra": "2008-02-13T00:00:00",
    "RegistreringFra": "2019-05-13T07:50:58.3081057",
    "sager_renoveringsstoette": 1,
    "sager_renoveringsstoette_organisation": null,
    "sager_renoveringsstoette_byggeri": null,
    "sager_infrastruktur": 1,
    "sager_infrastruktur_organisation": null,
    "sager_infrastruktur_byggeri": null,
    "sager_huslejestoette": null,
    "sager_huslejestoette_organisation": null,
    "sager_huslejestoette_byggeri": null,
    "sager_Kapitaltilfoersel": 2,
    "sager_Kapitaltilfoersel_organisation": null,
    "sager_Kapitaltilfoersel_byggeri": null,
    "sager_boligsocialindsats": null,
    "sager_boligsocialindsats_organisation": null,
    "sager_boligsocialindsats_byggeri": null,
    "sager_regnskabsgennemgang": null,
    "sager_regnskabsgennemgang_organisation": 14,
    "sager_regnskabsgennemgang_byggeri": null,
    "beliggenhed": "Brogade 30 (lejl. 1-30) og 30A (lejl. 33-48) / Brydevej 11A-H og 11K-N og 11P og 11R / Nyborgvej 254 / Nannasvej 3 / Kongebakken 15 / A P Møllers Vej 9 / Caroline Amalievej 11A-B / Ravnemøllevej 9A-13A / Frederiksgade 5A-D / Vestergade 47A-49E / Vestergade 41A+B / Bontvedvej 35A-L, Grundtvigsvej 5A-7L / Bryghusvej 44A-54F / Skovsbovej 69 / Fåborgvej 90",
    "Kommune": {
      "Kommunekode": 479,
      "Navn": "Svendborg kommune"
    },
    "Organisation": {
      "Nummer": 634,
      "Navn": "Cama-Kollegierne Svendborg"
    },
    "centroid_wkt": "POINT (602018.19394640031 6103192.1986082755)",
    "Byggerier": [{
      "ByggeriId": 7522,
      "BkByggeriId": "FB60B43F-6BDA-41E6-A2A7-494ABA5F4F36",
      "BkRecordKey": "R48798909109A24B1C1256E7B003F7D95",
      "ByggeriVersionId": 22274,
      "AfdelingId": 5387,
      "Nummer": 6,
      "NytNummer": null,
      "Navn": "Birkegården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "A P Møllers Vej 9",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1987-05-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1933",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.8695790",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (601793.71337648435 6101710.41328315)",
      "Boliger": [{
        "ByggeriVersionId": 22274,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 18,
        "EtageByggeriAreal": 512.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7524,
      "BkByggeriId": "7E5E644C-0D11-4B2E-B208-4B567A23149B",
      "BkRecordKey": "RF4C2298CBCD3FF86C1256E7B0044584A",
      "ByggeriVersionId": 22269,
      "AfdelingId": 5387,
      "Nummer": 8,
      "NytNummer": null,
      "Navn": "Ravnegården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Ravnemøllevej 9A-13A",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1989-10-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1960 og 1964",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.8119674",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (602758.97589827678 6103165.9650869742)",
      "Boliger": [{
        "ByggeriVersionId": 22269,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 0,
        "EtageByggeriAreal": 0.00,
        "BlandetByggeriAntal": 18,
        "BlandetByggeriAreal": 466.00
      }]
    }, {
      "ByggeriId": 7525,
      "BkByggeriId": "DAC1FDC9-AD02-4664-9EC7-A72394052C69",
      "BkRecordKey": "RBD0C64382911197EC1256E78002F939C",
      "ByggeriVersionId": 36715,
      "AfdelingId": 5387,
      "Nummer": 3,
      "NytNummer": null,
      "Navn": "Troldeskoven",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Nyborgvej 254",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1977-08-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1974",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2009-03-30T00:00:00",
      "RegistreringFra": "2019-05-13T08:01:02.0451467",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (605006.42306748591 6104206.1995439827)",
      "Boliger": [{
        "ByggeriVersionId": 36715,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 30,
        "LavtByggeriAreal": 841.00,
        "EtageByggeriAntal": 0,
        "EtageByggeriAreal": 0.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7527,
      "BkByggeriId": "F981B4D2-92FE-4383-A359-C86D72E06391",
      "BkRecordKey": "R89B6221B33A447EDC1256E7800303586",
      "ByggeriVersionId": 22276,
      "AfdelingId": 5387,
      "Nummer": 4,
      "NytNummer": null,
      "Navn": "Haraldsgården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Nannasvej 3",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1986-05-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1897",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.8950356",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (602280.24385555717 6102465.7278998662)",
      "Boliger": [{
        "ByggeriVersionId": 22276,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 10,
        "EtageByggeriAreal": 330.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7528,
      "BkByggeriId": "1E695AF4-7A60-4845-A547-40EAA7B95A1D",
      "BkRecordKey": "R62C0F5842F9D5D80C1256E7B002ED86F",
      "ByggeriVersionId": 22265,
      "AfdelingId": 5387,
      "Nummer": 5,
      "NytNummer": null,
      "Navn": "Bakkegården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Kongebakken 15",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1986-06-15T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1900",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.7796198",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (603074.95777840319 6103416.1509769168)",
      "Boliger": [{
        "ByggeriVersionId": 22265,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 24,
        "EtageByggeriAreal": 856.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7529,
      "BkByggeriId": "CAC1EC2F-3F24-44FA-A852-6B64BB2287FF",
      "BkRecordKey": "R16D1CEA05FA660E4C1256E780029A577",
      "ByggeriVersionId": 77796,
      "AfdelingId": 5387,
      "Nummer": 1,
      "NytNummer": null,
      "Navn": "Brogården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Brogade 30 (lejl. 1-30) og 30A (lejl. 33-48)",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1983-05-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1835, 1898",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2016-10-31T00:00:00",
      "RegistreringFra": "2019-05-13T08:15:17.4895111",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": null,
      "Boliger": [{
        "ByggeriVersionId": 77796,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 46,
        "EtageByggeriAreal": 1440.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7530,
      "BkByggeriId": "A2EB3612-B8C5-4D5F-BAF3-604B90382932",
      "BkRecordKey": "R2FA4317CA96677B0C1256E7B0040D024",
      "ByggeriVersionId": 82132,
      "AfdelingId": 5387,
      "Nummer": 7,
      "NytNummer": null,
      "Navn": "Ørkildsgården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Caroline Amalievej 11A-B",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1987-08-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1885  ",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2018-05-29T00:00:00",
      "RegistreringFra": "2019-05-13T08:16:45.6144591",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (603417.90133054927 6103182.0992614971)",
      "Boliger": [{
        "ByggeriVersionId": 82132,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 24,
        "EtageByggeriAreal": 1091.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7531,
      "BkByggeriId": "A7B8D563-BF4F-4633-A676-3AA4CA18219C",
      "BkRecordKey": "R31D7DF9B82434ABEC1256E7B00478DFF",
      "ByggeriVersionId": 71400,
      "AfdelingId": 5387,
      "Nummer": 9,
      "NytNummer": null,
      "Navn": "Frederiksgården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Frederiksgade 5A-D",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1991-08-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1890, 1897 og 1991",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2015-06-17T00:00:00",
      "RegistreringFra": "2019-05-13T08:11:57.0535699",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": null,
      "Boliger": [{
        "ByggeriVersionId": 71400,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 17,
        "EtageByggeriAreal": 645.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7532,
      "BkByggeriId": "81D57A5C-F00A-4EBB-88AE-88E5345826C2",
      "BkRecordKey": "RD7D14761CB8A9607C1256E7C0036E238",
      "ByggeriVersionId": 22290,
      "AfdelingId": 5387,
      "Nummer": 12,
      "NytNummer": null,
      "Navn": "Rømergården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Bontvedvej 35A-L, Grundtvigsvej 5A-7L",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1994-08-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1994",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:33.0195332",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (601387.46525287849 6102547.52623946)",
      "Boliger": [{
        "ByggeriVersionId": 22290,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 27,
        "LavtByggeriAreal": 722.00,
        "EtageByggeriAntal": 0,
        "EtageByggeriAreal": 0.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7534,
      "BkByggeriId": "3CA7844F-B331-4158-8CEB-D262AF1F3B23",
      "BkRecordKey": "R50B0BE0F7A7C9A1BC1256E7C002C02D8",
      "ByggeriVersionId": 22282,
      "AfdelingId": 5387,
      "Nummer": 11,
      "NytNummer": null,
      "Navn": "Nannagården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Vestergade 41A+B",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1993-01-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1887",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.9464234",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (602338.18380356941 6102643.9730714392)",
      "Boliger": [{
        "ByggeriVersionId": 22282,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 17,
        "EtageByggeriAreal": 653.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7535,
      "BkByggeriId": "F02FA736-92BF-4A34-8387-EE25F3C2651F",
      "BkRecordKey": "R4F75332EC8E57425C1256E7C002A99B0",
      "ByggeriVersionId": 77794,
      "AfdelingId": 5387,
      "Nummer": 10,
      "NytNummer": null,
      "Navn": "Slibemaen",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Vestergade 47A-49E",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1990-08-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1907 og 1938",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2016-10-31T00:00:00",
      "RegistreringFra": "2019-05-13T08:15:17.4437923",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": null,
      "Boliger": [{
        "ByggeriVersionId": 77794,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 20,
        "EtageByggeriAreal": 728.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7536,
      "BkByggeriId": "66270AC0-905C-4571-BF70-7E2F0635C81B",
      "BkRecordKey": "RCAE21D0ECA51FFC4C1256E7C00415EA9",
      "ByggeriVersionId": 22286,
      "AfdelingId": 5387,
      "Nummer": 14,
      "NytNummer": null,
      "Navn": "Skovgården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Skovsbovej 69",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1996-09-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1996",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.9791003",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (601069.17234237527 6101949.5152161159)",
      "Boliger": [{
        "ByggeriVersionId": 22286,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 80,
        "EtageByggeriAreal": 2304.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 7540,
      "BkByggeriId": "983BCD59-799D-4DB9-A751-7A34011824BA",
      "BkRecordKey": "R79FF01ECC78E8232C1256E7C003B6EFD",
      "ByggeriVersionId": 22280,
      "AfdelingId": 5387,
      "Nummer": 13,
      "NytNummer": null,
      "Navn": "Bryggergården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Bryghusvej 44A-54F",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1994-01-01T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1994",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-02-06T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:32.9346031",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (601838.99479919171 6102548.9433481889)",
      "Boliger": [{
        "ByggeriVersionId": 22280,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 36,
        "EtageByggeriAreal": 992.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 12251,
      "BkByggeriId": "2C90EFEA-2C21-4746-A27D-C565C2E5CFF3",
      "BkRecordKey": "R04525F6FCC749829C1256CD70060DADD",
      "ByggeriVersionId": 82134,
      "AfdelingId": 5387,
      "Nummer": 15,
      "NytNummer": null,
      "Navn": "Tre Roser",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": 479,
      "Beliggenhed": "Fåborgvej 90",
      "Postnummer": 5700,
      "BossId": "479 0030.0000 11-12-2000 1",
      "BossinfHovedProjektId": 7168,
      "Tilsagnsdato": "2000-12-11T00:00:00",
      "Skaeringsdato": "2002-01-08T00:00:00",
      "ErOmdannetSelvejendeInstitution": false,
      "OpfoerelsesAar": "1971",
      "Overtagelsesdato": null,
      "StoetteartKode": 1,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": 0,
      "ErhvervAreal": 0.00,
      "InstitutionAntal": 0,
      "InstitutionAreal": 0.00,
      "GarageAntal": 0,
      "GarageAreal": 0.00,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2018-05-29T00:00:00",
      "RegistreringFra": "2019-05-13T08:16:45.6908617",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (600363.08984599356 6103715.4174633818)",
      "Boliger": [{
        "ByggeriVersionId": 82134,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 84,
        "LavtByggeriAreal": 3181.00,
        "EtageByggeriAntal": 0,
        "EtageByggeriAreal": 0.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }, {
      "ByggeriId": 13778,
      "BkByggeriId": "F9ED581B-81B1-4813-82D8-E44E3174F88D",
      "BkRecordKey": "R8E2ACE8800663D5AC12573E600737905",
      "ByggeriVersionId": 23342,
      "AfdelingId": 5387,
      "Nummer": 2,
      "NytNummer": null,
      "Navn": "Bøgegården",
      "StatusKode": 1,
      "StatusKodeAlternativ": null,
      "TypeKode": 1,
      "KommuneKode": 479,
      "KommuneKodeFoerKommunalreform": null,
      "Beliggenhed": "Brydevej 11A-H og 11K-N og 11P og 11R",
      "Postnummer": 5700,
      "BossId": null,
      "BossinfHovedProjektId": null,
      "Tilsagnsdato": null,
      "Skaeringsdato": "1983-05-15T00:00:00",
      "ErOmdannetSelvejendeInstitution": true,
      "OpfoerelsesAar": "1934 og 1958",
      "Overtagelsesdato": "2003-08-01T00:00:00",
      "StoetteartKode": 3,
      "FriplejeTilvejebringelseKode": null,
      "FriplejeTilsagnsparagrafKode": null,
      "FriplejeBossIdHistorisk": null,
      "BossinfFriplejeHovedProjektIdHistorisk": null,
      "FriplejeTilsagnsdatoHistorisk": null,
      "FriplejeSkaeringsdatoHistorisk": null,
      "FriplejeTilsagnsparagrafKodeHistorisk": null,
      "ErhvervAntal": null,
      "ErhvervAreal": null,
      "InstitutionAntal": null,
      "InstitutionAreal": null,
      "GarageAntal": null,
      "GarageAreal": null,
      "AdministratorId": null,
      "AdministratorTilknytningTypeKode": null,
      "ServicearealByggeriId": null,
      "VirkningFra": "2008-03-10T00:00:00",
      "RegistreringFra": "2019-05-13T07:57:46.7866054",
      "sager_renoveringsstoette": null,
      "sager_infrastruktur": null,
      "sager_huslejestoette": null,
      "sager_Kapitaltilfoersel": null,
      "sager_boligsocialindsats": null,
      "sager_regnskabsgennemgang": null,
      "centroid_wkt": "POINT (601611.369385433 6102104.1466788277)",
      "Boliger": [{
        "ByggeriVersionId": 23342,
        "BoligTypeKode": 4,
        "LavtByggeriAntal": 0,
        "LavtByggeriAreal": 0.00,
        "EtageByggeriAntal": 14,
        "EtageByggeriAreal": 410.00,
        "BlandetByggeriAntal": 0,
        "BlandetByggeriAreal": 0.00
      }]
    }]
  }];
  _exports.afdelinger = afdelinger;
});