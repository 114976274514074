define("lbf/components/buildsection-header", ["exports", "lbf/ol/afdeling"], function (_exports, _afdeling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    antalBoliger: Ember.computed('byggeri.bygninger.[]', function () {
      var antal = 0;

      if (this.byggeri.bygninger) {
        this.byggeri.bygninger.forEach(function (bygning) {
          if (bygning.antalBoliger) {
            antal += bygning.antalBoliger;
          }
        });
      }

      return antal;
    }),
    mouseEnter: function mouseEnter() {
      var id = this.byggeri.byggeriId;
      this.state.set('hoveredPolygonID', id);
      (0, _afdeling.hoverByggeri)(id);
    },
    mouseLeave: function mouseLeave() {
      this.state.set('hoveredPolygonID', null);
      (0, _afdeling.hoverByggeri)(null);
    }
  });

  _exports.default = _default;
});