define("lbf/controllers/noaccess", ["exports", "lbf/utils/bruger", "lbf/config/environment"], function (_exports, _bruger, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showLogout: Ember.computed('show', function () {
      return _environment.default.APP.LOCATED !== 'Internal';
    }),
    actions: {
      signout: function signout() {
        (0, _bruger.signout)();
      }
    }
  });

  _exports.default = _default;
});