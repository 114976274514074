define("lbf/components/layer-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-check', 'form-check--btncheckbox', 'form-check--checkcolor'],
    selected: Ember.computed('state.activeLayers.[]', 'id', function () {
      return this.state.activeLayers.includes(this.id);
    }),
    id: Ember.computed.alias('layer.config.id'),
    title: Ember.computed.alias('layer.config.title'),
    legend: Ember.computed.alias('layer.config.legend'),
    updateValue: function updateValue() {
      if (this.state.activeLayers.includes(this.id)) {
        this.state.activeLayers.removeObject(this.id);
      } else {
        this.state.activeLayers.addObject(this.id);
      } //Added to trigger change event. Array types doesn't trigger change events


      this.state.save();
      this.selectChange();
    }
  });

  _exports.default = _default;
});