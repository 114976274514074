define("lbf/components/building-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    aar: Ember.computed('bygning.{omtilbygningsaar,opfoerelsesaar}', function () {
      return "".concat(this.bygning.opfoerelsesaar, " / ").concat(this.bygning.omtilbygningsaar || '-');
    }),
    bebyggetareal: Ember.computed('bygning.bbrArealer.bebyggetareal', function () {
      return this.getArea(this.bygning.bbrArealer.bebyggetareal);
    }),
    etageareal: Ember.computed('bygning.bbrArealer.etageareal', function () {
      return this.getArea(this.bygning.bbrArealer.etageareal);
    }),
    kaelderareal: Ember.computed('bygning.bbrArealer.kaelderareal', function () {
      return this.getArea(this.bygning.bbrArealer.kaelderareal);
    }),
    tagetageareal: Ember.computed('bygning.bbrArealer.tagetageareal', function () {
      return this.getArea(this.bygning.bbrArealer.tagetageareal);
    }),
    boligareal: Ember.computed('bygning.bbrArealer.boligareal', function () {
      return this.getArea(this.bygning.bbrArealer.boligareal);
    }),
    getArea: function getArea(area) {
      if (!area) {
        return '-';
      }

      var val = area || 0;
      return Ember.String.htmlSafe("".concat(val, " m<sup>2</sup>"));
    }
  });

  _exports.default = _default;
});