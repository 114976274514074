define("lbf/controllers/overblik/favoritter", ["exports", "lbf/ol/data", "lbf/ol/map", "lbf/ol/poi"], function (_exports, _data, _map, _poi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    afdelinger: Ember.computed.alias('model.afdelinger'),
    prevFavorites: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('prevFavorites', null);

      _map.map.on('pointermove', function (e) {
        if (e.dragging) {
          return;
        }

        var pixel = _map.map.getEventPixel(e.originalEvent);

        var features = [];

        _map.map.forEachFeatureAtPixel(pixel, function (feature) {
          features.push(feature);
          return false;
        }, {
          layerFilter: function layerFilter(l) {
            return l !== _poi.poiLayer && l !== _poi.poiLayerLabel;
          }
        });

        var id = null;

        if (features.length > 0 && features[0].get('data').lbfNummer) {
          id = features[0].get('data').lbfNummer;
        }

        _this.hover(id);
      });
    },
    initModel: function initModel() {
      (0, _data.addFavoriteData)(this.afdelinger, true);
    },
    hover: function hover(id) {
      (0, _data.hoverFavorite)(id);
      this.set('hovered', id);
    },
    afdelinger_liste: Ember.computed('afdelinger', 'limit', function () {
      if (this.afdelinger === null) {
        return [];
      }

      var list = [];
      this.afdelinger.forEach(function (a) {
        var last = list[list.length - 1];

        if (list.length === 0 || !last.header && last.organisation.organisationId !== a.organisation.organisationId) {
          list.push({
            type: 'header',
            organisation: a.organisation
          });
        }

        list.push(a);
      });
      return list;
    }),
    actions: {
      hideFavorites: function hideFavorites() {
        this.set('prevFavorites', null);
        this.transitionToRoute('overblik.afdelinger');
      },
      clearFavorites: function clearFavorites() {
        this.set('prevFavorites', this.state.favoriteAfdelinger.toArray());
        this.state.favoriteAfdelinger.clear();
        this.state.save();
      },
      undoClearFavorites: function undoClearFavorites() {
        if (this.prevFavorites && this.state.favoriteAfdelinger.length === 0) {
          this.state.favoriteAfdelinger.pushObjects(this.prevFavorites);
          this.set('prevFavorites', null);
          this.state.save();
        }
      },
      toggleCollapseRight: function toggleCollapseRight() {
        this.set('state.showRightPanel', !this.state.showRightPanel);
      },
      showOrganisation: function showOrganisation(nummer) {
        this.set('state.showOrganisation', nummer);
      },
      hover: function hover(id) {
        this.hover(id);
      },
      clearFilter: function clearFilter(type) {
        this.state.set(type, null);
        this.state.save();
        this.refreshModel();
      }
    }
  });

  _exports.default = _default;
});