define("lbf/controllers/overblik/afdeling", ["exports", "lbf/ol/afdeling", "lbf/ol/map", "lbf/config/chartoptions", "proj4"], function (_exports, _afdeling, _map, _chartoptions, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    afdeling: Ember.computed.alias('model'),
    user: null,
    showPaneContentl: 'A',
    zoomToAfdeling: function zoomToAfdeling() {
      var _this = this;

      Ember.run.later(this, function () {
        if (_this.state && _this.state.zoomOnSelect) {
          var padding = [30, 300, 30, 30];
          (0, _afdeling.zoomTo)(padding);
        }
      }, 500);
    },
    deselectInitialByggeri: function deselectInitialByggeri() {
      (0, _afdeling.selectByggeri)();
    },
    selectInitialByggeri: function selectInitialByggeri(id) {
      var _this2 = this;

      Ember.run.later(this, function () {
        (0, _afdeling.selectByggeri)(id, _this2.state && _this2.state.zoomOnSelect);
        var element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
          });
        }
      }, 500);
      var object = this.afdeling.bygninger.find(function (bygning) {
        return bygning.bygningId === id;
      });

      if (object) {
        object.set('expanded', true);
      } else {
        object = this.afdeling.byggerier.find(function (byggeri) {
          return byggeri.byggeriId.toString() === id.toString();
        });

        if (object) {
          object.set('expanded', true);
        }
      }
    },
    antalBoliger: Ember.computed('afdeling.byggerier.[]', function () {
      var antal = 0;
      this.afdeling.byggerier.forEach(function (byggeri) {
        if (byggeri.boliger) {
          for (var j = 0; j < byggeri.boliger.length; j++) {
            var bolig = byggeri.boliger[j];
            antal += bolig.lavtByggeriAntal;
            antal += bolig.etageByggeriAntal;
            antal += bolig.blandetByggeriAntal;
          }
        }
      });
      return antal;
    }),
    isFavorite: Ember.computed('state.favoriteAfdelinger.[]', 'afdeling.lbfNummer', function () {
      return this.state.favoriteAfdelinger.indexOf(this.afdeling.lbfNummer) !== -1;
    }),
    beboerindeks: Ember.computed('afdeling.beboerindeks', function () {
      if (typeof this.afdeling.beboerindeks === 'undefined' || this.afdeling.beboerindeks === null || isNaN(this.afdeling.beboerindeks)) {
        return 'Ej tilgængelig';
      }

      return (this.afdeling.beboerindeks - 0).toFixed(1).replace('.', ',');
    }),
    center: Ember.computed('state.{x,y}', function () {
      var p = (0, _proj.default)('EPSG:25832', 'EPSG:4326', [this.state.x, this.state.y]);
      return {
        x: p[0],
        y: p[1]
      };
    }),
    chartOptions: _chartoptions.chartOptions,
    chartData: Ember.computed('afdeling.byggerier.[]', function () {
      var chartData = {
        labels: ['Familiebolig', 'Ungdomsbolig', 'Ældrebolig'],
        datasets: [{
          label: 'Tæt/lav',
          backgroundColor: 'rgb(0, 176, 181)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }, {
          label: 'Etage',
          backgroundColor: 'rgb(190, 235, 236)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }, {
          label: 'Blandet',
          backgroundColor: 'rgb(128, 176, 217)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }]
      };
      var values = 0;
      this.afdeling.byggerier.forEach(function (byggeri) {
        if (byggeri.boliger) {
          for (var j = 0; j < byggeri.boliger.length; j++) {
            var bolig = byggeri.boliger[j];
            var index = 0;

            if (bolig.boligTypeKode === 4) {
              index = 1;
            } else if (bolig.boligTypeKode === 5) {
              index = 2;
            }

            chartData.datasets[0].data[index] += bolig.lavtByggeriAntal;
            chartData.datasets[1].data[index] += bolig.etageByggeriAntal;
            chartData.datasets[2].data[index] += bolig.blandetByggeriAntal;
            values += bolig.lavtByggeriAntal + bolig.etageByggeriAntal + bolig.blandetByggeriAntal;
          }
        }
      });

      if (values === 0) {
        return null;
      }

      return chartData;
    }),
    ready: false,
    initOnce: function initOnce() {
      var _this3 = this;

      if (this.ready === false) {
        this.ready = true;

        _map.map.on('pointermove', function (e) {
          if (e.dragging) {
            return;
          }

          var pixel = _map.map.getEventPixel(e.originalEvent);

          var features = [];

          _map.map.forEachFeatureAtPixel(pixel, function (feature) {
            features.push(feature);
            return false;
          });

          var id = null;

          if (features.length > 0) {
            var type = features[0].get('type');

            if (type === 'afsnit' || type === 'bygning') {
              id = features[0].get('id');

              _this3.hoverByggeri(features[0].get('id'), true);

              return;
            }
          }

          _this3.hoverByggeri(id, !!id);
        });

        _map.map.on('singleclick', function (e) {
          if (_this3.active) {
            var pixel = _map.map.getEventPixel(e.originalEvent);

            var features = [];

            _map.map.forEachFeatureAtPixel(pixel, function (feature) {
              features.push(feature);
              return false;
            });

            if (features.length > 0) {
              var type = features[0].get('type');

              if (type === 'afsnit' || type === 'bygning') {
                var id = features[0].get('id');

                if (_this3.state.selectedPolygonID !== id) {
                  _this3.selectByggeri(id);

                  return;
                }
              }
            }

            _this3.selectByggeri(null);
          }
        });
      }
    },
    initModel: function initModel(model, user) {
      this.set('user', user);
      this.set('selectedTab', 'afdeling-active');
      (0, _afdeling.addData)(this.afdeling.byggerier);
      (0, _afdeling.addBuildings)(this.afdeling.bygninger);
      this.initOnce();
    },
    selectByggeri: function selectByggeri(id) {
      if (id) {
        this.transitionToRoute('overblik.afdeling.bygning', id);
      } else {
        this.transitionToRoute('overblik.afdeling.bygninger');
      }
    },
    hoverByggeri: function hoverByggeri(id) {
      this.state.set('hoveredPolygonID', id);
      (0, _afdeling.hoverByggeri)(id);
    },
    selectedTab: 'afdeling-active',
    actions: {
      toggleByggeri: function toggleByggeri(id, expanded) {
        this.selectByggeri(expanded ? id : null);
      },
      showOrganisation: function showOrganisation(nummer) {
        this.set('state.showOrganisation', nummer);
      },
      hoverByggeafsnit: function hoverByggeafsnit(byggeafsnit, hover) {
        this.hoverByggeri(hover ? byggeafsnit.byggeriId : null);
      },
      selectedByggeafsnit: function selectedByggeafsnit(byggeafsnit, selected) {
        this.selectByggeri(selected ? byggeafsnit.byggeriId : null);
      },
      toggleFavorites: function toggleFavorites() {
        if (this.state.favoriteAfdelinger.indexOf(this.afdeling.lbfNummer) === -1) {
          this.state.favoriteAfdelinger.pushObject(this.afdeling.lbfNummer);
        } else {
          this.state.favoriteAfdelinger.removeObject(this.afdeling.lbfNummer);
        }

        this.state.save();
      },
      close: function close() {
        this.transitionToRoute(this.from ? this.from.name : 'overblik.afdelinger');
      }
    }
  });

  _exports.default = _default;
});