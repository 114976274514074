define("lbf/templates/components/sags-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiFLHfkz",
    "block": "[[[8,[39,0],[[24,0,\"my-2\"],[16,\"title\",[29,[[30,0,[\"name\"]]]]]],[[\"@type\",\"@color\"],[\"text\",[29,[[30,0,[\"color\"]]]]]],[[\"default\"],[[[[1,[30,0,[\"firstChar\"]]]],[]]]]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,[30,0,[\"sag\",\"agSagsnummer\"]]],[1,\" \"],[10,\"small\"],[14,0,\"ms-3 text-muted\"],[12],[1,[30,0,[\"sag\",\"agMilepael\"]]],[13]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"avatar\",\"list/list-item-content\",\"list/list-item-title\"]]",
    "moduleName": "lbf/templates/components/sags-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});