define("lbf/components/accordion/accordion-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-accordion-item border-bottom border-top'],
    classNameBindings: ['expanded:is-expanded', 'hovered:has-hover']
  });

  _exports.default = _default;
});