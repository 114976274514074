define("lbf/components/buildsection-content", ["exports", "lbf/config/sagstyper", "lbf/config/chartoptions"], function (_exports, _sagstyper, _chartoptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['box-cont'],
    tagName: 'div',
    chartOptions: _chartoptions.chartOptions,
    chartData: Ember.computed('afdeling', 'byggeri', function () {
      var chartData = {
        labels: ['Familiebolig', 'Ungdomsbolig', 'Ældrebolig'],
        datasets: [{
          label: 'Tæt/lav',
          backgroundColor: 'rgb(0, 176, 181)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }, {
          label: 'Etage',
          backgroundColor: 'rgb(190, 235, 236)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }, {
          label: 'Blandet',
          backgroundColor: 'rgb(128, 176, 217)',
          borderColor: 'white',
          borderWidth: 0,
          data: [0, 0, 0, 0, 0]
        }]
      };
      var values = 0;
      var byggeri = this.byggeri;

      if (byggeri.boliger) {
        for (var j = 0; j < byggeri.boliger.length; j++) {
          var bolig = byggeri.boliger[j];
          var index = 0;

          if (bolig.boligTypeKode === 4) {
            index = 1;
          } else if (bolig.boligTypeKode === 5) {
            index = 2;
          }

          chartData.datasets[0].data[index] += bolig.lavtByggeriAntal;
          chartData.datasets[1].data[index] += bolig.etageByggeriAntal;
          chartData.datasets[2].data[index] += bolig.blandetByggeriAntal;
          values += bolig.lavtByggeriAntal + bolig.etageByggeriAntal + bolig.blandetByggeriAntal;
        }
      }

      if (values === 0) {
        return null;
      }

      return chartData;
    }),
    sager: Ember.computed('byggeri.sager', function () {
      if (!this.byggeri.sager) {
        return [];
      }

      var sager = this.byggeri.sager.filter(function (sag) {
        return sag.driftstoette && sag.driftstoette.journalnr;
      });
      return sager;
    }),
    visibleClassNames: Ember.computed('afdeling', 'visibleSagstyper', function () {
      return this.visibleSagstyper.map(function (name) {
        return getClassName(name);
      });
    })
  });

  _exports.default = _default;

  function getClassName(name) {
    for (var i = 0; i < _sagstyper.sagstyper.length; i++) {
      var st = _sagstyper.sagstyper[i];

      if (st.name === name) {
        return st.className;
      }
    }

    return false;
  }
});