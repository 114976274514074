define("lbf/components/list/list-item-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-advanced-list-item__icon'],
    classNameBindings: ['colorClassName'],
    colorClassName: Ember.computed('color', function () {
      return "bg-".concat(this.color);
    })
  });

  _exports.default = _default;
});