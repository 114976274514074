define("lbf/components/accordion/accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-accordion', 'p-0'],
    classNameBindings: ['is-open', 'color', 'card:c-accordion--cards', 'expanded'],
    mouseEnter: function mouseEnter() {
      if (this.hover) {
        this.hover(true);
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.hover) {
        this.hover(false);
      }
    },
    actions: {
      open: function open() {
        this.set('is-open', !this.open);

        if (this.change) {
          this.change(this.open);
        }
      }
    }
  });

  _exports.default = _default;
});