define("lbf/components/sags-link", ["exports", "lbf/config/sagstyper"], function (_exports, _sagstyper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    // classNames: ['list-group-item d-flex justify-content-between align-items-center'],
    classNames: ['c-advanced-list-item'],
    // Samme klasser som advanced list item
    classNameBindings: ['colorBg'],
    name: Ember.computed('sag.agSagstypeId', function () {
      var id = this.sag.agSagstypeId;
      return this.getSagstypeName(id);
    }),
    color: Ember.computed('sag.agSagstypeId', function () {
      var id = this.sag.agSagstypeId;
      return this.getClassName(id);
    }),
    colorBg: Ember.computed('sag.agSagstypeId', function () {
      var id = this.sag.agSagstypeId;
      return 'bg-tint-' + this.getClassName(id);
    }),
    firstChar: Ember.computed('sag.agSagstypeId', function () {
      if (this.sag.agSagstypeId === 6) {
        return 'RG';
      } else {
        var name = this.getSagstypeName(this.sag.agSagstypeId);

        if (name) {
          return name.charAt(0);
        }

        return '-';
      }
    }),
    getClassName: function getClassName(id) {
      for (var i = 0; i < _sagstyper.sagstyper.length; i++) {
        var st = _sagstyper.sagstyper[i];

        if (st.id === id) {
          return st.className;
        }
      }

      return null;
    },
    getSagstypeName: function getSagstypeName(id) {
      for (var i = 0; i < _sagstyper.sagstyper.length; i++) {
        var st = _sagstyper.sagstyper[i];

        if (st.id === id) {
          return st.name;
        }
      }

      return null;
    }
  });

  _exports.default = _default;
});