define("lbf/components/boligtype-selector", ["exports", "lbf/ol/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    id: Ember.computed.alias('boligtype.value'),
    title: Ember.computed.alias('boligtype.name'),
    icon: Ember.computed.alias('boligtype.icon'),
    classNames: ['form-check', 'form-check--btncheckbox', 'form-check--checkcolor'],
    classNameBindings: ['boligtypeClassName'],
    boligtypeClassName: Ember.computed('icon', function () {
      return "boligtype-".concat(this.icon);
    }),
    selected: Ember.computed('state.activeBoligtypeLayers.[]', 'id', function () {
      return this.state.activeBoligtypeLayers.includes(this.id);
    }),
    updateValue: function updateValue() {
      if (this.state.activeBoligtypeLayers.includes(this.id)) {
        this.state.activeBoligtypeLayers.removeObject(this.id);
      } else {
        this.state.activeBoligtypeLayers.addObject(this.id);
      } //Added to trigger change event. Array types doesn't trigger change events


      this.state.save();
      (0, _data.showBoligtyper)(this.state.activeBoligtypeLayers);
    }
  });

  _exports.default = _default;
});