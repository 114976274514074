define("lbf/utils/poi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.config = void 0;
  var config = [{
    title: 'Daginstitution',
    layers: [{
      icon: 'daginstitution',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48"> <path d="M41.39,28.267V44.206H60.813c0-9.2-7.1-17.01-16.656-18.322a2.413,2.413,0,0,0-2.746,1.966A1.99,1.99,0,0,0,41.39,28.267ZM56.734,57.9A17.955,17.955,0,0,0,60.813,46.52H25.341L23.69,43.281A2.438,2.438,0,0,0,21.5,41.893H17.11a2.316,2.316,0,1,0,0,4.627h2.841s4.589,9.416,5.147,10.225A7.907,7.907,0,0,0,21.59,67.7a8.712,8.712,0,0,0,11.494,3.345A8.125,8.125,0,0,0,37.5,65.027h5.05a8.441,8.441,0,0,0,9.61,6.875,8.174,8.174,0,0,0,7.216-9.159,8,8,0,0,0-2.768-4.911ZM29.25,67.34a3.474,3.474,0,1,1,3.642-3.47A3.557,3.557,0,0,1,29.25,67.34Zm21.852,0a3.474,3.474,0,1,1,3.642-3.47A3.557,3.557,0,0,1,51.1,67.34Z" transform="translate(-13.748 -24.927)"/> </svg>',
      id: 'Daginstitution',
      background: '#0C395D',
      color: '#FFFFFF',
      title: 'Daginstitution',
      types: ['vuggestue', 'boernehave', 'integreret']
    }, {
      icon: 'grundskole',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-11.76 0.958)"> <g transform="translate(18.958)"> <path d="M31.355,11.6a.461.461,0,0,0,.4-.691L26.939.618a1,1,0,0,0-1.728,0L20.18,10.91a.461.461,0,0,0,.422.691Z" transform="translate(-19.738 -0.081)"/> <path d="M19.438,118.125a.48.48,0,0,0-.48.48v.48a6.24,6.24,0,0,0,12.481,0v-.48a.48.48,0,0,0-.48-.48Z" transform="translate(-18.958 -79.242)"/> <path d="M86.856,0h-9.6a2.88,2.88,0,0,0-2.88,2.88v2.4a.48.48,0,0,0,.48.48h3.36a.96.96,0,1,1,0,1.92h-3.36a.48.48,0,0,0-.48.48v4.8a.48.48,0,0,0,.48.48h3.36a.96.96,0,1,1,0,1.92h-3.36a.48.48,0,0,0-.48.48v4.8a.48.48,0,0,0,.48.48h3.36a.96.96,0,1,1,0,1.92h-3.36a.48.48,0,0,0-.48.48v4.8a.48.48,0,0,0,.48.48h3.36a.96.96,0,1,1,0,1.92h-3.36a.48.48,0,0,0-.48.48v12a2.88,2.88,0,0,0,2.88,2.88h9.6a2.88,2.88,0,0,0,2.88-2.88V2.88A2.88,2.88,0,0,0,86.856,0Zm-4.8,37.443a2.4,2.4,0,1,1-2.4,2.4A2.4,2.4,0,0,1,82.055,37.443Z" transform="translate(-56.134)"/> <g transform="translate(0 13.441)"> <path d="M24.239,41.793a.96.96,0,0,0-.96-.96h-3.84a.48.48,0,0,0-.48.48V63.875a.48.48,0,0,0,.48.48h3.84a.96.96,0,0,0,.96-.96Z" transform="translate(-18.958 -40.833)"/> <path d="M46.114,41.313a.48.48,0,0,0-.48-.48h-3.84a.96.96,0,0,0-.96.96v21.6a.96.96,0,0,0,.96.96h3.84a.48.48,0,0,0,.48-.48Z" transform="translate(-33.633 -40.833)"/> </g> </g> </g> </svg>',
      id: 'Grundskole',
      background: '#0C395D',
      color: '#FFFFFF',
      title: 'Grundskole',
      types: ['grundskole']
    }]
  }, {
    title: 'Videreuddannelse',
    layers: [{
      icon: 'uddannelse',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M22.1,144.927v6.461a4.6,4.6,0,0,0,2.3,4.047l11.5,6.277a4.6,4.6,0,0,0,4.415,0l11.5-6.277a4.6,4.6,0,0,0,2.3-4.047v-6.461l-13.8,7.588a4.6,4.6,0,0,1-4.415,0Zm13.8-22.212L16.6,133.431a2.3,2.3,0,0,0,0,4.047l19.383,10.577a4.6,4.6,0,0,0,4.415,0L58.8,137.96v13.589a2.3,2.3,0,1,0,4.6,0V136.719a2.3,2.3,0,0,0-1.2-2.023L40.4,122.762a4.59,4.59,0,0,0-4.415,0Z" transform="translate(-15.396 -118.236)"/> </svg>',
      id: 'Uddannelse',
      background: '#0C395D',
      color: '#FFFFFF',
      title: 'Uddannelse',
      types: ['gymnasieskole', 'erhvervsskole', 'videregaaende']
    }]
  }, {
    title: 'Transport',
    layers: [{
      icon: 'togstation',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-9.72 -171.446)"> <path d="M44.806,198h2.4l-6.671-8.832h-2.4l1.343,1.766H27.132l1.343-1.766H26.092L19.378,198h2.4l1.343-1.766h20.3ZM24.6,194.446l1.343-1.766h14.9l1.343,1.766Z" transform="translate(0.368 21.448)"/> <path d="M44.935,174.273c-2.71-2.471-7.441-2.827-11.312-2.827s-8.6.356-11.226,2.827-3.419,11.508-3.419,19.432.387,11.553,1.505,13.355,2.86,1.825,6.452,1.825H40.5c3.548,0,5.355-.312,6.452-1.825s1.505-5.364,1.505-13.355S47.623,176.655,44.935,174.273Zm-11.312-.757c.645,0,2.344,0,2.344,1.647s-1.634,1.647-2.344,1.647-2.344,0-2.344-1.647S33.021,173.516,33.623,173.516Zm-7.871,31.675a2.74,2.74,0,1,1,2.645-2.782h0A2.761,2.761,0,0,1,25.752,205.191Zm15.828,0a2.74,2.74,0,1,1,2.645-2.738h0a2.694,2.694,0,0,1-2.645,2.738ZM21.215,193.483s-.3-10.551,1.656-12.576,7.828-2.026,10.753-2.026,8.817,0,10.753,2.026,1.634,12.576,1.634,12.576Q33.623,194.06,21.215,193.483Z" transform="translate(0 0)"/> </g> </svg>',
      id: 'Togstation',
      background: '#1A73E8',
      color: '#FFFFFF',
      title: 'Togstation',
      types: ['tog']
    }, {
      icon: 's-togstation',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M55.469,225.537a6.7,6.7,0,0,0-5.966-3.4H32.443a6.616,6.616,0,0,0-6.009,3.4l-8.616,14.388a6.465,6.465,0,0,0,0,6.893l8.508,14.388a6.721,6.721,0,0,0,6.009,3.4H49.309a6.614,6.614,0,0,0,6.009-3.4l8.508-14.388a6.456,6.456,0,0,0,0-6.893ZM40.154,254.572a68.6,68.6,0,0,1-9.391-.8v-3.748a56.659,56.659,0,0,0,7.862.8c1.852,0,6.1,0,6.1-2.692,0-4.846-13.591-.409-13.591-9.219,0-5.945,7.108-6.742,10.77-6.742q3.987.129,7.948.646v3.489c-2-.151-5.342-.56-8.142-.56-1.508,0-4.566.129-4.566,2.154,0,4.653,13.914.323,13.914,9.456,0,5.428-5.708,7.151-10.985,7.173Z" transform="translate(-16.823 -219.372)"/> </svg>',
      id: 'S-togstation',
      background: '#1A73E8',
      color: '#FFFFFF',
      title: 'S-togstation',
      types: ['s-tog']
    }, {
      icon: 'metro',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M62.75,298.2H53.138L51.31,275.626v-.365H43.2v.237L40.58,288.4l-2.387-12.9v-.237H29.635v.28l-1.806,22.579H18.259a1.311,1.311,0,0,0-1.247,1.374v8.6a1.291,1.291,0,0,0,1.224,1.355H62.793a1.354,1.354,0,0,0,1.333-1.355v-8.6a1.356,1.356,0,0,0-1.333-1.376h0Zm-29.31,0,1.054-16.214,3.57,16.214Zm12.9-16.3,1.183,16.3H42.709l3.72-16.3" transform="translate(-16.568 -268.355)"/> </svg>',
      id: 'Metro',
      background: '#1A73E8',
      color: '#FFFFFF',
      title: 'Metro',
      types: ['metro']
    }, {
      icon: 'letbane',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-6.791 -320.902)"> <path d="M37.318,339.838h-2.19l1.27,1.248,3.7,3.61,1.292,1.21h2.19Zm-13.579,0-6.374,5.971h2.19l1.27-1.229,3.767-3.61,1.27-1.229h-2.19Z" transform="translate(0.258 22.997)"/> <path d="M44.361,341.014a44.639,44.639,0,0,0-.827-6.905,9.37,9.37,0,0,0-2.177-5.006,14.323,14.323,0,0,0-8.707-2.592L34,323.785h6.1V320.9H21.4v2.771h8.947l-1.35,2.726a14.376,14.376,0,0,0-8.707,2.592c-2.547,2.235-3.2,10.726-3.2,18.123s.348,10.749,1.415,12.38,2.59,1.631,5.856,1.631H37.112a18.785,18.785,0,0,0,3.5-.246A3.39,3.39,0,0,0,43.1,359.4c.849-1.251,1.219-3.642,1.393-8.2v-4.179C44.514,345.081,44.47,342.958,44.361,341.014Zm-13.605-12.76c.61,0,2.177,0,2.177,1.542s-1.546,1.52-2.177,1.52-2.177,0-2.177-1.52S30.168,328.254,30.756,328.254Zm-4.92,27.039a2.548,2.548,0,1,1,0-.045Zm13.671,2.235a2.331,2.331,0,0,1-1.241.4h0a2.547,2.547,0,1,1,2.5-2.57,2.46,2.46,0,0,1-1.306,2.1Zm2.982-10.547H39.507c-6.814.224-13.605.179-20.441,0,0,0-.3-9.855,1.524-11.732s7.358-1.9,10.188-1.9,8.294,0,10.188,1.9a1.76,1.76,0,0,1,.239.38c1.589,2.436,1.284,11.285,1.284,11.285Z" transform="translate(0 0)"/> </g> </svg>',
      id: 'Letbane',
      background: '#1A73E8',
      color: '#FFFFFF',
      title: 'Letbane',
      types: ['letbane']
    }, {
      icon: 'busstop',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path id="Path_101" data-name="Path 101" d="M784.742,410.074H763.475a7.79,7.79,0,0,0-7.767,7.767v23.245a7.78,7.78,0,0,0,3.9,6.721v3.234a1.3,1.3,0,0,0,1.3,1.3h6.314a1.3,1.3,0,0,0,1.3-1.3v-2.189H779.3v2.187a1.3,1.3,0,0,0,1.3,1.3h6.312a1.3,1.3,0,0,0,1.3-1.3v-3.018a7.782,7.782,0,0,0,4.3-6.935V417.841A7.789,7.789,0,0,0,784.742,410.074Zm-20.679,33.287a2.988,2.988,0,1,1,2.988-2.988A2.988,2.988,0,0,1,764.063,443.361Zm19.691,0a2.988,2.988,0,1,1,2.988-2.988A2.988,2.988,0,0,1,783.754,443.361Zm4.323-13.455a.886.886,0,0,1-.884.884h-26.17a.887.887,0,0,1-.885-.884V419.633a.887.887,0,0,1,.885-.884h26.17a.886.886,0,0,1,.884.884Z" transform="translate(-750.108 -407.206)"/> </svg>',
      id: 'Busstop',
      background: '#1A73E8',
      color: '#FFFFFF',
      title: 'Busstop',
      types: ['bus']
    }]
  }, {
    title: 'Offentlig service',
    layers: [{
      icon: 'kulturhus',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="white" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-21.246 -471.568)"> <path d="M69.171,490.382a2.57,2.57,0,0,0-.8-2.568,39.154,39.154,0,0,0-13.031-7.661v-3.162a1.715,1.715,0,0,0-.964-1.543,35.246,35.246,0,0,0-32.156,0,1.716,1.716,0,0,0-.969,1.543v9.4a22.234,22.234,0,0,0,9.048,17.9,25.4,25.4,0,0,0,7.925,12.687,10.621,10.621,0,0,0,6.95,2.592,10.338,10.338,0,0,0,2.335-.261,25.547,25.547,0,0,0,19.143-18.858ZM61.658,499.2a20.422,20.422,0,0,1-15.3,15.088,5.444,5.444,0,0,1-4.771-1.193,20.422,20.422,0,0,1-6.373-20.52l1.994-7.981a.868.868,0,0,1,.686-.634,30.775,30.775,0,0,1,25.453,6.388.856.856,0,0,1,.305.881Z" transform="translate(0)"/> <path d="M38.1,488.757a2.576,2.576,0,0,1-2.459-1.82.957.957,0,0,0-1.2-.644.947.947,0,0,0-.405.242,2.571,2.571,0,0,1-3.628-3.644,6.1,6.1,0,0,1,10.149,2.547,2.563,2.563,0,0,1-2.462,3.319Z" transform="translate(7.173 8.109)"/> <path d="M45.5,490.613a2.575,2.575,0,0,1-2.459-1.824.96.96,0,0,0-1.6-.4,2.572,2.572,0,0,1-3.628-3.646,6.1,6.1,0,0,1,10.149,2.547,2.568,2.568,0,0,1-1.706,3.208A2.61,2.61,0,0,1,45.5,490.613Z" transform="translate(13.463 9.686)"/> </g> </svg>',
      id: 'Kulturhus',
      background: '#743D98',
      color: '#FFFFFF',
      title: 'Kulturhus',
      types: ['kulturhus_spillested']
    }, {
      icon: 'bibliotek',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-112.669 -411.915)"> <path d="M135.4,422.546a.434.434,0,0,0-.223-.381c-2.553-1.433-7.638-3.347-16.7-3.461a1.456,1.456,0,0,0-1.236.5,1.754,1.754,0,0,0-.521,1.243v27.392a1.739,1.739,0,0,0,1.713,1.738c9.364.12,14.217,2.22,16.309,3.5a.433.433,0,0,0,.6-.14.442.442,0,0,0,.064-.231Z" transform="translate(0 0)"/> <path d="M130.21,422.546a.436.436,0,0,1,.222-.381c2.553-1.433,7.64-3.347,16.7-3.461a1.451,1.451,0,0,1,1.235.5,1.749,1.749,0,0,1,.522,1.243v27.392a1.74,1.74,0,0,1-1.715,1.738c-9.362.12-14.215,2.22-16.307,3.5a.433.433,0,0,1-.6-.14.442.442,0,0,1-.065-.231Z" transform="translate(7.734 0)"/> </g> </svg>',
      id: 'Bibliotek',
      background: '#743D98',
      color: '#FFFFFF',
      title: 'Bibliotek',
      types: ['bibliotek']
    }, {
      icon: 'borgerservice',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M651.287,864.424l9.143-3.975V880.44a36.748,36.748,0,0,1,5.278-2.279,14.041,14.041,0,0,1,17.643,11.02c1.121,5.473.207,10.55-3.637,14.849a14.215,14.215,0,0,1-18.253,1.983c-.256-.183-.525-.349-.786-.523-.092.125-.16.175-.167.233-.227,1.821-.226,1.828-2.071,1.82-2.383-.01-4.767-.032-7.15-.048Zm23.624,28.76c.016-4.557-3.016-7.887-7.2-7.9a7.822,7.822,0,0,0-.066,15.623C671.814,900.922,674.9,897.647,674.911,893.184Z" transform="translate(-643.287 -860.449)" fill="#428414"/> </svg>',
      id: 'Borgerservice',
      // background: '#428414',
      background: '#FFFFFF',
      color: '#428414',
      title: 'Borgerservice',
      types: ['borgerservice']
    }, {
      icon: 'hospital',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M39.185,568.09a24,24,0,1,0,24,24,24,24,0,0,0-24-24Zm11,34a1,1,0,0,1-1,1H43.193a1,1,0,0,1-1-1h0V596.1a1,1,0,0,0-1-1H37.181a1,1,0,0,0-1,1v5.989a1,1,0,0,1-1,1H29.189a1,1,0,0,1-1-1h0V582.094a1,1,0,0,1,1-1h5.989a1,1,0,0,1,1,1v5.989a1,1,0,0,0,1,1h4.008a1,1,0,0,0,1-1v-5.989a1,1,0,0,1,1-1h5.989a1,1,0,0,1,1,1h0Z" transform="translate(-15.185 -568.09)"/> </svg>',
      id: 'Hospital',
      background: '#B42419',
      color: '#FFFFFF',
      title: 'Hospital',
      types: ['hospital']
    }, {
      icon: 'laegepraksis',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-17.445 -620.876)"> <path d="M38.222,626.069a1.029,1.029,0,0,0-1.4,0L20.808,641.4a1.031,1.031,0,0,0-.32.732v19.908a1.994,1.994,0,0,0,1.968,2.014H52.593a2.014,2.014,0,0,0,2.014-2.014V642.133a1.021,1.021,0,0,0-.32-.732Zm9.336,24.142a1.1,1.1,0,0,1-1.076,1.121h-4.6a1.007,1.007,0,0,0-1.007,1.007h0v4.577a1.122,1.122,0,0,1-1.121,1.121H35.293a1.123,1.123,0,0,1-1.121-1.121h0v-4.577a1.007,1.007,0,0,0-1.007-1.007H28.589a1.123,1.123,0,0,1-1.121-1.121v-4.462a1.122,1.122,0,0,1,1.121-1.121h4.577a1.007,1.007,0,0,0,1.007-1.007v-4.577a1.122,1.122,0,0,1,1.121-1.121h4.462a1.12,1.12,0,0,1,1.121,1.121v4.577a.987.987,0,0,0,.961,1.007H46.46a1.1,1.1,0,0,1,1.1,1.1v.023h0Z" transform="translate(3.92 5.05)"/> <path d="M64.934,643.544l-22.105-21.1a1.967,1.967,0,0,0-2.769,0l-22.105,21.1a2.014,2.014,0,0,0,2.769,2.906l20.023-19.107a.983.983,0,0,1,1.391,0l0,0L62.165,646.45a2.014,2.014,0,0,0,2.769-2.906Z" transform="translate(0 0)"/> </g> </svg>',
      id: 'Lægepraksis',
      background: '#B42419',
      color: '#FFFFFF',
      title: 'Lægepraksis',
      types: ['almen_laegepraksis']
    }, {
      icon: 'jobcenter',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-380.749 -226.539)"> <path d="M401.468,251.938l2.081,17.953a12.717,12.717,0,0,0,.383,1.931l11.649,2.019c4.861.842,9.3-3.414,9.918-9.508l2.04-20.19a12.043,12.043,0,0,0,.057-1.578l-17.1-.449A8.717,8.717,0,0,0,401.468,251.938Z" transform="translate(-9.919 -7.197)" fill="#fff"/> <g transform="translate(399.541 236.849)"> <path d="M426.6,258.2c-.131-.516-2.234-.867-4.608-.516-2.3.339-4.124,1.007-4.232,1.528h0s-.345,12.027-.508,16.723l-.347,2.833a19.073,19.073,0,0,0,3.556-1.79q2.479-1.7,2.665-3.367l3.463-15.267a.227.227,0,0,0,.019-.083" transform="translate(-416.904 -251.454)" fill="#003567"/> <path d="M423.06,251c-.976-.637-.914-2.254.135-3.609s2.684-1.938,3.66-1.3.916,2.253-.133,3.61S424.036,251.637,423.06,251Z" transform="translate(-419.528 -245.83)" fill="#003567"/> </g> <path d="M431.678,243.024l-1.748-.045a12.054,12.054,0,0,1-.057,1.578l-2.04,20.19c-.616,6.094-5.056,10.351-9.918,9.508l-11.65-2.019c1.273,4.576,5.015,8.314,9.013,8.682l14.858.924c4.416.139,8.373-4.439,8.89-10.113l1.913-18.382C441.576,247.93,437.429,243.305,431.678,243.024Z" transform="translate(-12.254 -7.612)" fill="#81aed8"/> <path d="M408.817,227.2l-18.15,2.362c-5.477.713-9.918,4.969-9.918,9.506v15.036c0,4.538,3.941,8.9,8.8,9.742l4.46.774a12.721,12.721,0,0,1-.383-1.931l-2.081-17.953a8.717,8.717,0,0,1,9.026-9.823l17.089.449C417.508,230.122,413.807,226.555,408.817,227.2Zm8.847,8.162c0,.011,0,.022,0,.033l.011,0c0-.009,0-.02,0-.029Z" transform="translate(0)" fill="#cdc200"/> </g> </svg>',
      id: 'Jobcenter',
      background: '#06427C',
      color: '#FFFFFF',
      title: 'Jobcenter',
      types: ['jobcenter']
    }]
  }, {
    title: 'Idrætsfaciliteter',
    layers: [{
      icon: 'idraet',
      svg: '<svg fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <g transform="translate(-77.85 -93.005)"> <path d="M105.175,112.965c-.8,2.012-1.6,3.983-2.366,5.964-.045.116.082.367.2.469a16.462,16.462,0,0,1,5.062,17.752,2.532,2.532,0,1,1-4.842-1.481,12.7,12.7,0,0,0,.394-5.611,11.7,11.7,0,0,0-2.644-5.568c-.2.793-.4,1.538-.578,2.288a9.464,9.464,0,0,1-6.773,7.12c-2.107.679-4.247,1.263-6.381,1.856a2.73,2.73,0,0,1-3.3-1.8,2.559,2.559,0,0,1,1.971-3.19c2.079-.589,4.167-1.147,6.23-1.788a4.841,4.841,0,0,0,3.534-3.907,45.267,45.267,0,0,1,1.415-5.205c.856-2.545,1.838-5.047,2.767-7.568.093-.252.184-.5.319-.877-.792-.155-1.524-.356-2.269-.432-1.9-.194-3.775-.1-5.078,1.557a7.989,7.989,0,0,0-1.1,2.219,2.635,2.635,0,0,1-2.657,1.855,2.441,2.441,0,0,1-2.239-1.984,3.394,3.394,0,0,1,.093-1.5,9.394,9.394,0,0,1,7.358-7.052,15.26,15.26,0,0,1,5.113-.014,38.264,38.264,0,0,1,6.294,1.728,17.783,17.783,0,0,0,4.741.648,3.26,3.26,0,0,0,3.2-1.953,14.512,14.512,0,0,0,.97-3.155,2.55,2.55,0,0,1,2.659-2.148,2.511,2.511,0,0,1,2.386,2.677,11.726,11.726,0,0,1-2.349,6.417,8.4,8.4,0,0,1-6.575,3.363,15.116,15.116,0,0,1-5.076-.571C105.527,113.031,105.382,113.01,105.175,112.965Z"/> <path d="M111.267,100.174a5.072,5.072,0,1,1-5.1-5.09A5.06,5.06,0,0,1,111.267,100.174Z"/> </g> </svg>',
      id: 'Idraet',
      background: '#26762C',
      color: '#FFFFFF',
      title: 'Idræt',
      types: ['stor_idraetshal', 'lille_idraetshal', 'tennishal', 'badmintonhal', 'skatefacilitet', 'parkouranlaeg', 'fodboldanlaeg', 'svoemmeanlaeg', 'is_skoejteanlaeg', 'ro_kajakanlaeg']
    }]
  }, {
    title: 'Indkøb',
    layers: [{
      icon: 'dagligvare',
      svg: '<svg  fill="white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> <path d="M-795.527,949.7c-16.646-.054-28.568-.069-29.784.027a1.919,1.919,0,0,1-1.336-.321,1.827,1.827,0,0,1-.556-1.093c-.377-1.715-.76-1.9-2.565-1.906-1.912,0-3.824-.01-5.737,0-1.34.01-2.051.623-2.034,1.721.017,1.055.628,1.537,2.029,1.556,1.289.017,2.579,0,3.851,0,.144.618.26,1.09.363,1.564,1.825,8.195,3.662,16.384,5.447,24.589a2.525,2.525,0,0,1-.047,1.114,3.338,3.338,0,0,0-.521,1.258,3.415,3.415,0,0,0-.059.628,3.362,3.362,0,0,0,3.361,3.361,3.317,3.317,0,0,0,1.357-.286,3.347,3.347,0,0,0,2.009-3.076,3.348,3.348,0,0,0-.491-1.751h17.13a3.361,3.361,0,0,0-.681,2.023,3.362,3.362,0,0,0,3.362,3.362h.06a3.024,3.024,0,0,0,1.462-.363,3.357,3.357,0,0,0,1.842-2.994,3.334,3.334,0,0,0-.812-2.188,1.8,1.8,0,0,0,1.127-1.465,1.856,1.856,0,0,0-1.54-1.88l-1.686-.03-20.507-.03a4.377,4.377,0,0,1-1.569-.035,1.839,1.839,0,0,1-.946-.385,2.148,2.148,0,0,1-.524-.859,4.215,4.215,0,0,1-.2-.609,1.347,1.347,0,0,1,.172-.988,1.324,1.324,0,0,1,.752-.559c15.554-1.735,27.091-3.176,27.7-3.423a4.395,4.395,0,0,0,1.336-.846h0a2.376,2.376,0,0,0,.471-1.1V951.891a2.964,2.964,0,0,0-.8-1.422A2.959,2.959,0,0,0-795.527,949.7Z" transform="translate(839.414 -940.43)"/> </svg>',
      id: 'Dagligvarer',
      background: '#EA681E',
      color: '#FFFFFF',
      title: 'Dagligvarer',
      types: ['supermarked']
    }]
  }];
  _exports.config = config;
});