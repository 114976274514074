define("lbf/components/map-panel", ["exports", "lbf/ol/map"], function (_exports, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['l-map'],
    attributeBindings: ['tabindex'],
    tabindex: 0,
    didInsertElement: function didInsertElement() {
      var map = this.map;
      map.setTarget(this.element);
      this.checkSize(this.checkInterval);

      if (this.state) {
        var view = this.map.getView();

        if (this.state.bbox) {
          view.fit(this.state.bbox, {
            minResolution: 0.2
          });
        } else if (this.state && this.state.x && this.state.y) {
          view.setCenter([this.state.x, this.state.y]);
          view.setZoom(this.state.zoom);
        }
      }

      _map.scaleline.setTarget(document.getElementById('scaleline'));

      map.addControl(_map.scaleline);
    },
    width: null,
    checkInterval: 10,
    checkSize: function checkSize(interval) {
      var _this = this;

      if (!this.isDestroyed) {
        var width = this.element.firstChild.offsetWidth;
        var height = this.element.firstChild.offsetHeight;

        if (this.width === null) {
          this.width = width;
        }

        if (this.height === null) {
          this.height = height;
        }

        if (this.width !== width || this.height !== height) {
          this.map.updateSize();
          this.width = width;
          this.height = height;
        }

        Ember.run.later(function () {
          return _this.checkSize(interval);
        }, interval);
      }
    }
  });

  _exports.default = _default;
});