define("lbf/components/list/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-advanced-list'],
    attributeBindings: ['role'],
    role: "list",
    classNameBindings: ['typeClassName', 'dense:c-advanced-list--dense'],
    typeClassName: Ember.computed('type', function () {
      if (this.type == "card") {
        return 'c-advanced-list--cards';
      } else if (this.type == "cases") {
        return 'c-advanced-list--cases';
      }

      return '';
    })
  });

  _exports.default = _default;
});