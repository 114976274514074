define("lbf/components/sags-bits", ["exports", "lbf/config/sagstyper"], function (_exports, _sagstyper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sager'],
    tagName: 'li',
    attributeBindings: ['title'],
    title: Ember.computed.reads('visibleTitles'),
    visibleSagstyper: Ember.computed('afdeling.sagstyper.[]', 'state.sagstyperSelected.[]', function () {
      var typer = this.afdeling.sagstyper;
      var sagstyperSelected = this.state.sagstyperSelected; // if (sagstyperSelected.length === 0) {
      //   return typer
      // }

      return typer.filter(function (s) {
        return sagstyperSelected.includes(s);
      });
    }),
    visibleClassNames: Ember.computed('afdeling', 'visibleSagstyper', function () {
      return this.visibleSagstyper.map(function (name) {
        return getClassName(name);
      }).reverse();
    }),
    visibleTitles: Ember.computed('afdeling', 'visibleSagstyper', function () {
      var titles = this.visibleSagstyper.map(function (name) {
        return getTitle(name);
      });
      return titles.join(', ');
    })
  });

  _exports.default = _default;

  function getClassName(name) {
    for (var i = 0; i < _sagstyper.sagstyper.length; i++) {
      var st = _sagstyper.sagstyper[i];

      if (st.name === name) {
        return st.className;
      }
    }

    return false;
  }

  function getTitle(name) {
    for (var i = 0; i < _sagstyper.sagstyper.length; i++) {
      var st = _sagstyper.sagstyper[i];

      if (st.name === name) {
        return st.title;
      }
    }

    return null;
  }
});