define("lbf/components/metadata/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-metadata'],
    classNameBindings: ['justify:c-metadata--justify'] // når man sætter cols="2" skal den skrive klassen .row-cols-2 og .row

  });

  _exports.default = _default;
});