define("lbf/components/modal-organisation", ["exports", "lbf/utils/organisation", "lbf/utils/afdeling"], function (_exports, _organisation, _afdeling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal'],
    afdelingerHeader: Ember.computed('afdelingerCount', function () {
      var count = this.afdelingerCount || 0;
      return Ember.String.htmlSafe("Boligafdelinger <span class=\"text-muted\">(".concat(count, ")</span>"));
    }),
    organisationNummer: Ember.computed('state.showOrganisation', function () {
      var organisationNummer = this.state.showOrganisation;

      if (organisationNummer) {
        this.loadOrganisation(organisationNummer);
        this.loadAfdelinger(organisationNummer);
        return (organisationNummer - 0 + 10000).toString().substr(1, 4);
      }

      return null;
    }),
    loadOrganisation: function loadOrganisation(organisationNummer) {
      var _this = this;

      (0, _organisation.getOrganisation)(organisationNummer).then(function (org) {
        _this.set('organisation', org);
      });
    },
    loadAfdelinger: function loadAfdelinger(organisationNummer) {
      var _this2 = this;

      this.set('loading', true);
      (0, _afdeling.getAfdelingerByOrganisation)(organisationNummer).then(function (resp) {
        _this2.set('afdelinger', resp.features);

        _this2.set('afdelingerCount', resp.totalFeatures);

        _this2.set('loading', false);
      });
    },
    link: Ember.computed('organisation.webSiteUrl', function () {
      if (this.organisation && this.organisation.webSiteUrl) {
        var url = this.organisation.webSiteUrl;

        if (url.match(/http:\/\//) || url.match(/https:\/\//)) {
          return url;
        } else {
          return 'https://' + url;
        }
      }

      return null;
    }),
    afdelinger_liste: Ember.computed('afdelinger', 'limit', function () {
      if (this.afdelinger === null) {
        return [];
      }

      var list = [];
      this.afdelinger.forEach(function (a) {
        var last = list[list.length - 1];

        if (list.length === 0 || !last.header && last.organisation.organisationId !== a.organisation.organisationId) {
          list.push({
            type: 'header',
            organisation: a.organisation
          });
        }

        list.push(a);
      });
      return list;
    }),
    actions: {
      showAfdeling: function showAfdeling(lbfNummer) {
        this.showAfdeling(lbfNummer);
        this.state.set('showOrganisation', null);
      },
      showOrganisation: function showOrganisation(nummer) {
        this.set('state.showOrganisation', nummer);
      },
      hideOrganisation: function hideOrganisation() {
        this.state.set('showOrganisation', null);
      },
      close: function close() {
        this.set('show', null);
      }
    }
  });

  _exports.default = _default;
});