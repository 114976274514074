define("lbf/components/list/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName: 'li',
    classNames: ['c-advanced-list-item'],
    // attributeBindings: ['tabindex', 'role'],
    attributeBindings: ['role'],
    // tabindex: "0",
    role: "listitem",
    classNameBindings: ['linesClassName'],
    linesClassName: Ember.computed('lines', function () {
      if (this.lines == "1") {
        return '';
      } else if (this.lines == "2") {
        return 'c-advanced-list-item--two-line';
      } else if (this.lines == "3") {
        return 'c-advanced-list-item--three-line';
      }

      return '';
    }),
    mouseEnter: function mouseEnter() {
      if (this.hover) {
        this.hover(this.lbfnummer);
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.hover) {
        this.hover(null);
      }
    }
  });

  _exports.default = _default;
});