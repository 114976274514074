define("lbf/components/afdeling-about", ["exports", "lbf/ol/afdeling", "lbf/config/chartoptions"], function (_exports, _afdeling, _chartoptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chartOptions: _chartoptions.chartOptions,
    hasOpenSager: Ember.computed('afdeling.sager.[]', 'user', function () {
      return this.afdeling.sager ? this.afdeling.sager.any(function (sag) {
        return !sag.agAfsluttet;
      }) : false;
    }),
    showBeboerindex: Ember.computed('afdeling.adgangBeboerindeks', 'user', function () {
      if (!this.user) {
        return false;
      }

      return this.afdeling.adgangBeboerindeks;
    }),
    center: Ember.computed('point', function () {
      if (!this.point) {
        return {
          x: 0,
          y: 0
        };
      }

      return {
        x: this.point[0],
        y: this.point[1]
      };
    }),
    imageDistance: Ember.computed('afdeling.lokationer', function () {
      if (!this.afdeling.lokationer) {
        return 0;
      }

      var lok = this.afdeling.lokationer[0];
      var bredde = lok.bredde * 1.2;
      return parseInt(bredde < 200 ? bredde / 2 : 100);
    }),
    point: Ember.computed('afdeling.lokationer', function () {
      if (!this.afdeling.lokationer) {
        return null;
      }

      var lok = this.afdeling.lokationer[0];
      var part = lok.centroide.split('(')[1].split(' ');
      var x = part[0] - 0;
      var y = part[1].split(')')[0] - 0;
      return [x, y];
    }),
    kommune: Ember.computed('afdeling.kommune.navn', function () {
      return "".concat(this.afdeling.kommune.navn);
    }),
    image1: Ember.computed('point', 'imageDistance', 'afdeling.lokationer', function () {
      var radius = this.getRadius(1.4, this.afdeling.lokationer[0].bredde, this.afdeling.lokationer[0].hoejde, true);
      return "url(https://skraafoto.septima.dk/api/image_of_point?token=931957e0-7937-4008-b2bb-5f937ea25ed3&bgcolor=0xf1f6f8&x=".concat(this.point[0], "&y=").concat(this.point[1], "&epsg=25832&direction=n&width=512&height=512&radius=").concat(radius, ")");
    }),
    image2: Ember.computed('point', 'imageDistance', 'afdeling.lokationer', function () {
      var radius = this.getRadius(1.5, this.afdeling.lokationer[0].bredde, this.afdeling.lokationer[0].hoejde, true);
      return "url(https://skraafoto.septima.dk/api/image_of_point?token=931957e0-7937-4008-b2bb-5f937ea25ed3&bgcolor=0xf1f6f8&x=".concat(this.point[0], "&y=").concat(this.point[1], "&epsg=25832&direction=w&width=512&height=512&radius=").concat(radius, ")");
    }),
    image3: Ember.computed('point', 'imageDistance', 'afdeling.lokationer', function () {
      var radius = this.getRadius(1.5, this.afdeling.lokationer[0].bredde, this.afdeling.lokationer[0].hoejde, true);
      return "url(https://skraafoto.septima.dk/api/image_of_point?token=931957e0-7937-4008-b2bb-5f937ea25ed3&bgcolor=0xf1f6f8&x=".concat(this.point[0], "&y=").concat(this.point[1], "&epsg=25832&direction=e&width=512&height=512&radius=").concat(radius, ")");
    }),
    actions: {
      zoomTo: function zoomTo() {
        var padding = [30, 300, 30, 30];
        (0, _afdeling.zoomTo)(padding);
      }
    },
    // areal
    grundareal: Ember.computed('afdeling.geometriAreal', function () {
      return this.getArea(this.afdeling.geometriAreal);
    }),
    bebyggetareal: Ember.computed('afdeling.bbrArealer.bebyggetareal', function () {
      return this.getArea(this.afdeling.bbrArealer.bebyggetareal);
    }),
    etageareal: Ember.computed('afdeling.bbrArealer.etageareal', function () {
      return this.getArea(this.afdeling.bbrArealer.etageareal);
    }),
    kaelderareal: Ember.computed('afdeling.bbrArealer.kaelderareal', function () {
      return this.getArea(this.afdeling.bbrArealer.kaelderareal);
    }),
    tagetageareal: Ember.computed('afdeling.bbrArealer.tagetageareal', function () {
      return this.getArea(this.afdeling.bbrArealer.tagetageareal);
    }),
    boligareal: Ember.computed('afdeling.bbrArealer.boligareal', function () {
      return this.getArea(this.afdeling.bbrArealer.boligareal);
    }),
    getArea: function getArea(area) {
      if (!area) {
        return '-';
      }

      var val = area || 0;
      return Ember.String.htmlSafe("".concat(val, " m<sup>2</sup>"));
    },
    getRadius: function getRadius(imageRatio, geomWidth, geomHeight, isDirectionNS) {
      if (!isDirectionNS) imageRatio = 1 / imageRatio;
      var scale = 1;
      var max = Math.max(geomWidth * scale, geomHeight * imageRatio * scale);
      return Math.min(parseInt(max / 2), 100);
    }
  });

  _exports.default = _default;
});