define("lbf/ol/map", ["exports", "ol/Map.js", "ol/View.js", "ol/interaction.js", "ol/control.js", "ol/proj/Projection.js", "ol/proj.js", "ol/extent.js", "ol/proj/proj4.js", "proj4"], function (_exports, _Map, _View, _interaction, _control, _Projection, _proj, _extent, _proj2, _proj3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.origin = _exports.map = _exports.scaleline = void 0;
  var projection = new _Projection.default({
    code: 'EPSG:25832',
    extent: [120000, 5661139.2, 958860.8, 6500000],
    units: 'm'
  });
  (0, _proj.addProjection)(projection);

  _proj3.default.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

  (0, _proj2.register)(_proj3.default);
  var scaleline = new _control.ScaleLine();
  _exports.scaleline = scaleline;
  var map = new _Map.default({
    logo: false,
    controls: (0, _control.defaults)({
      attribution: false,
      zoom: false
    }),
    interactions: (0, _interaction.defaults)({
      altShiftDragRotate: false,
      pinchRotate: false
    }),
    view: new _View.default({
      center: [591859, 6365817],
      extent: [420000, 6025000, 905000, 6450000],
      zoom: 1,
      maxResolution: 819.2,
      maxZoom: 18,
      enableRotation: false,
      projection: projection
    })
  });
  _exports.map = map;
  var projectionExtent = projection.getExtent();
  var origin = (0, _extent.getTopLeft)(projectionExtent);
  _exports.origin = origin;
  map.on('pointermove', function (e) {
    if (e.dragging) {
      return;
    }

    var pixel = map.getEventPixel(e.originalEvent); // const hit = map.hasFeatureAtPixel(pixel)
    // Only hit on afdelinger

    var features = [];
    map.forEachFeatureAtPixel(pixel, function (feature) {
      features.push(feature);
      return false;
    });
    var hit = features.length > 0 && (features[0].get('data').lbfNummer || features[0].get('type') === 'afsnit' || features[0].get('type') === 'bygning');
    map.getTarget().style.cursor = hit ? 'pointer' : '';
  });
});