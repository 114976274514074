define("lbf/components/list/list-item-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-advanced-list__action ps-2']
  });

  _exports.default = _default;
});