define("lbf/components/afdeling-building-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterType: 'all',
    countAll: Ember.computed('countAfsnit', 'countBygning', function () {
      return this.countAfsnit + this.countBygning;
    }),
    countAfsnit: Ember.computed('afdeling.byggerier.[]', function () {
      return this.afdeling.byggerier.get('length');
    }),
    countBygning: Ember.computed('afdeling.byggerier.[]', function () {
      var count = 0;
      this.afdeling.byggerier.forEach(function (byggeri) {
        count += byggeri.bygninger.length;
      });
      return count;
    }),
    actions: {
      setFilterType: function setFilterType(type) {
        this.set('filterType', type);
      }
    }
  });

  _exports.default = _default;
});