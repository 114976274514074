define("lbf/templates/components/sagstype-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FkciW01",
    "block": "[[[10,\"input\"],[14,0,\"form-check-input\"],[14,2,\"\"],[15,1,[29,[\"id-\",[30,0,[\"case\"]]]]],[15,\"checked\",[30,0,[\"selected\"]]],[15,\"onclick\",[28,[37,0],[[30,0],[30,0,[\"updateValue\"]]],[[\"value\"],[\"target.checked\"]]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[10,\"label\"],[14,0,\"form-check-label btn\"],[15,\"for\",[29,[\"id-\",[30,0,[\"case\"]]]]],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[\"@sagstype\"],false,[\"action\"]]",
    "moduleName": "lbf/templates/components/sagstype-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});