define("lbf/components/poilabel-selector", ["exports", "lbf/ol/poi"], function (_exports, _poi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-check', 'form-check--btncheckbox', 'form-check--checkcolor'],
    selected: Ember.computed('state.activePoiLabels.[]', 'id', function () {
      return this.state.activePoiLabels.includes(this.id);
    }),
    id: Ember.computed.alias('label.value'),
    title: Ember.computed.alias('label.name'),
    updateValue: function updateValue() {
      if (this.state.activePoiLabels.includes(this.id)) {
        this.state.activePoiLabels.removeObject(this.id);
      } else {
        this.state.activePoiLabels.addObject(this.id);
      } //Added to trigger change event. Array types doesn't trigger change events


      this.state.save();
      (0, _poi.showPoiLabels)(this.state.activePoiLabels);
    }
  });

  _exports.default = _default;
});