define("lbf/templates/components/list/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IYeOdyvF",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,\"aria-label\",[30,2]],[16,\"title\",[30,2]],[16,0,[52,[30,3],\"active c-advanced-list__goto\",\"c-advanced-list__goto\"]]],[[\"@route\",\"@model\"],[\"overblik.afdeling\",[30,1]]],[[\"default\"],[[[],[]]]]],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"  \"],[10,3],[15,6,[36,3]],[14,0,\"c-advanced-list__goto\"],[15,\"aria-label\",[30,2]],[15,\"title\",[30,2]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[13],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"    \"],[11,\"button\"],[24,0,\"text-start c-advanced-list__goto\"],[16,\"aria-label\",[30,2]],[16,\"title\",[30,2]],[24,4,\"button\"],[4,[38,4],[[30,0],[33,5]],null],[12],[13],[1,\"\\n    \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,6,null],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"@lbfnummer\",\"@title\",\"@hovered\",\"@href\",\"@click\",\"&default\"],false,[\"if\",\"link-to\",\"yield\",\"href\",\"action\",\"click\"]]",
    "moduleName": "lbf/templates/components/list/list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});