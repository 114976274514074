define("lbf/config/boligtyper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.boligtyperToKoder = boligtyperToKoder;
  _exports.boligtyper = void 0;
  var boligtyper = [{
    name: 'Familiebolig',
    value: '0',
    icon: 'familieboliger'
  }, {
    name: 'Ungdomsbolig',
    value: '4',
    icon: 'ungdomsboliger'
  }, {
    name: 'Ældrebolig',
    value: '5',
    icon: 'aeldreboliger'
  }];
  _exports.boligtyper = boligtyper;

  function boligtyperToKoder() {
    var boligtype = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var boligtypekode = [];

    if (boligtype.indexOf('0') !== -1) {
      boligtypekode.push(1, 2, 3);
    }

    if (boligtype.indexOf('4') !== -1) {
      boligtypekode.push(4);
    }

    if (boligtype.indexOf('5') !== -1) {
      boligtypekode.push(5);
    }

    return boligtypekode;
  }
});