define("lbf/ol/afdeling", ["exports", "lbf/ol/map", "ol/Collection", "ol/layer.js", "ol/source.js", "ol/style.js", "ol/format/WKT.js", "lbf/ol/data"], function (_exports, _map, _Collection, _layer, _source, _style, _WKT, _data2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showPolygonLayer = showPolygonLayer;
  _exports.hidePolygonLayer = hidePolygonLayer;
  _exports.zoomTo = zoomTo;
  _exports.highlightByggeafsnit = highlightByggeafsnit;
  _exports.polygonLayerBuilding = _exports.polygonLayer = _exports.styleFunction = _exports.selectByggeri = _exports.hoverByggeri = _exports.addBuildings = _exports.addData = void 0;
  var visible = false;

  function showPolygonLayer() {
    if (visible === false) {
      visible = true;

      if (polygonLayer) {
        polygonLayer.setVisible(visible);
        polygonLayerBuilding.setVisible(visible);
      }
    }
  }

  function hidePolygonLayer() {
    if (visible === true) {
      visible = false;

      if (polygonLayer) {
        polygonLayer.setVisible(visible);
        polygonLayerBuilding.setVisible(visible);
      }
    }
  }

  function zoomTo() {
    var padding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [30, 30, 30, 30];
    var extent = vectorSource.getExtent();

    if (vectorSource.getFeatures().length === 0) {
      extent = [420000, 6025000, 905000, 6450000];
    }

    _map.map.getView().fit(extent, {
      minResolution: 0.2,
      duration: 2000,
      padding: padding
    });
  }

  function highlightByggeafsnit(byggeafsnit, selected) {
    if (byggeafsnit._feature) {
      byggeafsnit._feature.set('selected', selected);

      if (selected) {
        _map.map.getView().fit(byggeafsnit._feature.getGeometry().getExtent(), {
          minResolution: 0.2,
          duration: 2000,
          padding: [30, 30, 30, 30]
        });
      }
    }
  }

  var addData = function addData(byggeafsnit) {
    features.clear();

    if (byggeafsnit) {
      var format = new _WKT.default(); // let count = 0

      byggeafsnit.forEach(function (ba) {
        if (typeof ba.geometri !== 'undefined' && ba.geometri !== null) {
          if (typeof ba._feature === 'undefined') {
            ba._feature = format.readFeature(ba.geometri);

            ba._feature.set('selected', false);

            ba._feature.set('id', ba.byggeriId);

            ba._feature.set('data', ba);

            ba._feature.set('type', 'afsnit');
          }

          features.push(ba._feature);
        }
      });
    }
  };

  _exports.addData = addData;

  var addBuildings = function addBuildings(buildings) {
    featuresBuilding.clear();

    if (buildings) {
      var format = new _WKT.default(); // let count = 0

      buildings.forEach(function (ba) {
        if (typeof ba.geometri !== 'undefined' && ba.geometri !== null) {
          if (typeof ba._feature === 'undefined') {
            ba._feature = format.readFeature(ba.geometri);

            ba._feature.set('selected', false);

            ba._feature.set('id', ba.bygningId);

            ba._feature.set('data', ba);

            ba._feature.set('type', 'bygning');
          }

          featuresBuilding.push(ba._feature);
        }
      });
    }
  };

  _exports.addBuildings = addBuildings;
  var hoveredFeature = null;

  var hoverByggeri = function hoverByggeri(id) {
    if (hoveredFeature !== null) {
      hoveredFeature.set('hovered', false);
      hoveredFeature = null;
    }

    if (id !== null) {
      var array = features.getArray().concat(featuresBuilding.getArray());

      for (var i = 0; i < array.length; i++) {
        var feature = array[i];

        if (feature.get('id') === id) {
          hoveredFeature = feature;
          feature.set('hovered', true);
          break;
        }
      }
    }
  };

  _exports.hoverByggeri = hoverByggeri;
  var selectedFeature = null;

  var selectByggeri = function selectByggeri(id, zoom) {
    if (selectedFeature !== null) {
      selectedFeature.set('selected', false);
      selectedFeature = null;
    }

    if (id) {
      var array = features.getArray().concat(featuresBuilding.getArray());

      for (var i = 0; i < array.length; i++) {
        var feature = array[i];
        var featureId = feature.get('id');

        if (featureId && featureId.toString() === id.toString()) {
          selectedFeature = feature;
          feature.set('selected', true);

          if (zoom) {
            _map.map.getView().fit(feature.getGeometry().getExtent(), {
              minResolution: 0.2,
              duration: 2000,
              padding: [30, 300, 30, 30]
            });
          }

          break;
        }
      }
    }
  };

  _exports.selectByggeri = selectByggeri;

  var styleFunction = function styleFunction(feature, resolution) {
    var style = [];

    if (feature.get('hovered')) {
      style.push(new _style.Style({
        stroke: new _style.Stroke({
          color: 'rgb(32, 51, 58)',
          width: 3
        }),
        fill: new _style.Fill({
          color: 'rgba(255, 255, 255, 0.6)'
        })
      }));
    } else if (feature.get('selected')) {
      style.push(new _style.Style({
        stroke: new _style.Stroke({
          color: 'rgb(73, 174, 182)',
          width: 3
        }),
        fill: new _style.Fill({
          color: 'rgba(73, 174, 182, 0.6)'
        })
      }));
    } else {
      var data = feature.get('data');

      if (data.geometriKlasse && data.geometriKlasse === 2) {
        style.push(new _style.Style({
          stroke: new _style.Stroke({
            color: 'rgb(73, 174, 182)',
            width: 2,
            lineDash: [4]
          }),
          fill: new _style.Fill({
            color: 'rgba(73, 174, 182,0.0)'
          })
        }));
      } else {
        style.push(new _style.Style({
          stroke: new _style.Stroke({
            color: 'rgb(73, 174, 182)',
            width: 2
          }),
          fill: new _style.Fill({
            color: 'rgba(73, 174, 182,0.3)'
          })
        }));
      }
    }

    if (!feature.get('type')) {
      var labels = (0, _data2.getLabelStyle)(feature, true);

      if (labels) {
        style.push(labels);
      }
    } else if (feature.get('type') === 'bygning' && resolution < 0.5) {
      var labelFillColor = 'rgba(30,30,30,1)';
      var labelStrokeColor = 'rgba(255,255,255,1)';

      var _data = feature.get('data');

      var bygningsnummer = _data.bygningsnummer + '';
      style.push(new _style.Style({
        zIndex: 1300,
        text: new _style.Text({
          textAlign: 'center',
          textBaseline: 'middle',
          font: 'normal 10px/1 sans-serif',
          overflow: true,
          text: bygningsnummer,
          fill: new _style.Fill({
            color: labelFillColor
          }),
          stroke: new _style.Stroke({
            color: labelStrokeColor,
            width: 3
          })
        })
      }));
    }

    return style;
  };

  _exports.styleFunction = styleFunction;
  var features = new _Collection.default();
  var vectorSource = new _source.Vector({
    features: features
  });
  var polygonLayer = new _layer.Vector({
    visible: visible,
    source: vectorSource,
    style: styleFunction,
    zIndex: 1000
  });
  _exports.polygonLayer = polygonLayer;

  _map.map.addLayer(polygonLayer);

  var featuresBuilding = new _Collection.default();
  var vectorSourceBuilding = new _source.Vector({
    features: featuresBuilding
  });
  var polygonLayerBuilding = new _layer.Vector({
    visible: visible,
    source: vectorSourceBuilding,
    style: styleFunction,
    zIndex: 1001
  });
  _exports.polygonLayerBuilding = polygonLayerBuilding;

  _map.map.addLayer(polygonLayerBuilding);
});