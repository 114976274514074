define("lbf/templates/components/list/list-item-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMJJvuHz",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "lbf/templates/components/list/list-item-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});