define("lbf/templates/components/closeable-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J57LXyWE",
    "block": "[[[11,0],[24,\"role\",\"button\"],[24,0,\"slide\"],[4,[38,0],[[30,0],\"open\"],null],[12],[1,[28,[35,1],null,[[\"name\"],[\"plus\"]]]],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"action\",\"icons\",\"yield\"]]",
    "moduleName": "lbf/templates/components/closeable-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});