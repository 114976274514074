define("lbf/components/legend/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h3 class="visually-hidden">Signaturforklaring til kortet:</h3>
  <ul>
    {{#each @legend as |legendConfig|}}
      <Legend::Item @config={{legendConfig}}></Legend::Item>
    {{/each}}
  </ul>
  
  */
  {
    "id": "ohNRSevw",
    "block": "[[[10,\"h3\"],[14,0,\"visually-hidden\"],[12],[1,\"Signaturforklaring til kortet:\"],[13],[1,\"\\n\"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@config\"],[[30,2]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@legend\",\"legendConfig\"],false,[\"each\",\"-track-array\",\"legend/item\"]]",
    "moduleName": "lbf/components/legend/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['c-signatur--new']
  }));

  _exports.default = _default;
});