define("lbf/components/building-header", ["exports", "lbf/ol/afdeling"], function (_exports, _afdeling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mouseEnter: function mouseEnter() {
      var id = this.bygning.bygningId;
      this.state.set('hoveredPolygonID', id);
      (0, _afdeling.hoverByggeri)(id);
    },
    mouseLeave: function mouseLeave() {
      this.state.set('hoveredPolygonID', null);
      (0, _afdeling.hoverByggeri)(null);
    }
  });

  _exports.default = _default;
});