define("lbf/services/poi", ["exports", "lbf/ol/poi", "lbf/utils/poi"], function (_exports, _poi, _poi2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    config: _poi2.config,
    show: function show(layerIds) {
      if (layerIds && layerIds.length) {
        var types = this.config.flatMap(function (group) {
          return group.layers.filter(function (l) {
            return layerIds.indexOf(l.id) !== -1;
          }).flatMap(function (l) {
            return l.types;
          });
        });
        (0, _poi.showPoiTypes)(types);
      } else {
        (0, _poi.showPoiTypes)();
      }
    }
  });

  _exports.default = _default;
});