define("lbf/templates/components/building-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "paEAmNa7",
    "block": "[[[8,[39,0],[[24,0,\"border-0\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"text-primary\"],[16,\"title\",[33,2,[\"almenGisBoligtypeTekst\"]]]],null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],null,[[\"name\"],[[52,[28,[37,5],[[33,2,[\"almenGisBoligtypeKode\"]],1],null],\"bygning\",\"bygning-secondary\"]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,6],[[24,0,\"py-0\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[33,2,[\"navn\"]]],[1,\" \"],[10,1],[14,0,\"fw-normal\"],[12],[1,\"- \"],[1,[33,2,[\"anvTekst\"]]],[13],[1,\" \"],[41,[51,[33,2,[\"geometri\"]]],[[[1,\"(ingen geometri)\"]],[]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,9],null,null,[[\"default\"],[[[[1,[33,2,[\"bygningsadresse\"]]]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"list/list-item\",\"list/list-item-icon\",\"bygning\",\"icons\",\"if\",\"eq\",\"list/list-item-content\",\"list/list-item-title\",\"unless\",\"list/list-item-subtitle\"]]",
    "moduleName": "lbf/templates/components/building-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});