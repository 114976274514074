define("lbf/components/sagstype-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-check', 'form-check--btncheckbox'],
    classNameBindings: ['typeClassName'],
    typeClassName: Ember.computed('case', function () {
      return "form-check--".concat(this.case);
    }),
    selected: Ember.computed('state.sagstyperSelected.[]', 'id', function () {
      return this.state.sagstyperSelected.includes(this.id);
    }),
    id: Ember.computed.alias('sagstype.name'),
    case: Ember.computed.alias('sagstype.className'),
    updateValue: function updateValue() {
      if (this.state.sagstyperSelected.includes(this.id)) {
        this.state.sagstyperSelected.removeObject(this.id);
      } else {
        this.state.sagstyperSelected.addObject(this.id);
      } //Added to trigger change event. Array types doesn't trigger change events


      this.state.set('sagstyperSelectedText', this.state.sagstyperSelected.join(','));
      this.state.save();
      this.sagstypeSelectedChange();
    }
  });

  _exports.default = _default;
});