define("lbf/templates/components/metadata/metadata-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b6Qv7HTk",
    "block": "[[[41,[33,1],[[[1,\"    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,[34,1]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[8,[39,4],null,null,[[\"default\"],[[[[1,[34,3]]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"title\",\"metadata/metadata-title\",\"text\",\"metadata/metadata-text\"]]",
    "moduleName": "lbf/templates/components/metadata/metadata-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});