define("lbf/components/metadata/metadata-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-metadata-item', 'col'],
    classNameBindings: ['remove-margin-bottom:mb-0:mb-3'] // margin-bottom="false" - default er true 

  });

  _exports.default = _default;
});