define("lbf/components/modal/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { Modal } from 'bootstrap'
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var element = this.element.children[0];
      var modal = new bootstrap.Modal(element, {
        backdrop: false
      });
      element.addEventListener('hidden.bs.modal', function () {
        _this.closeModal();
      });
      modal.show();
    },
    actions: {
      dropshadowClick: function dropshadowClick() {
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});