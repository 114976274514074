define("lbf/templates/components/accordion-buildsection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkXXP1/d",
    "block": "[[[8,[39,0],[[16,1,[33,1,[\"byggeriId\"]]]],[[\"@expanded\",\"@hovered\"],[[33,1,[\"expanded\"]],[28,[37,2],[[33,3,[\"hoveredPolygonID\"]],[33,1,[\"byggeriId\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@expanded\",\"@change\"],[[33,1,[\"expanded\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@byggeri\",\"@state\"],[[36,1],[30,2]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[33,1,[\"expanded\"]],[[[1,\"      \"],[8,[39,7],[[24,0,\"pb-5\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,8],null,[[\"@byggeri\"],[[36,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@change\",\"@state\"],false,[\"accordion/accordion-item\",\"byggeri\",\"eq\",\"state\",\"accordion/accordion-header\",\"buildsection-header\",\"if\",\"accordion/accordion-collapse\",\"buildsection-content\"]]",
    "moduleName": "lbf/templates/components/accordion-buildsection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});