define("lbf/controllers/index", ["exports", "lbf/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isPublic: _environment.default.APP.LOCATED === 'Public',
    helpUrl: _environment.default.APP.LOCATED === 'Public' ? 'https://lbf.dk/magasin/almengis-off/' : 'https://lbf.dk/selvbetjeninger/almengis/'
  });

  _exports.default = _default;
});