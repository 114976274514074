define("lbf/components/fold-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['open'],
    actions: {
      change: function change() {
        this.set('open', !this.open);
        this.change();
      }
    }
  });

  _exports.default = _default;
});