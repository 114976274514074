define("lbf/templates/components/accordion/accordion-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cjpiknba",
    "block": "[[[11,\"button\"],[24,0,\"c-accordion-button btn btn-white w-100 d-flex justify-content-between align-items-center px-0 mx-0 py-2 row\"],[16,\"aria-expanded\",[52,[33,1],\"true\",\"false\"]],[24,4,\"button\"],[4,[38,2],[[30,0],\"toggleExpanded\"],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"col text-start\"],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-auto text-end c-accordion-icon\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"            \"],[1,[28,[35,4],null,[[\"class\",\"name\"],[\"c-icon--size-2\",\"minus\"]]]],[1,\"\\n            \"],[10,1],[14,0,\"visually-hidden\"],[12],[1,\"Minimer\"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,4],null,[[\"class\",\"name\"],[\"c-icon--size-2\",\"plus\"]]]],[1,\"\\n            \"],[10,1],[14,0,\"visually-hidden\"],[12],[1,\"Udvid\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"expanded\",\"action\",\"yield\",\"icons\"]]",
    "moduleName": "lbf/templates/components/accordion/accordion-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});