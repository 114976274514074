define("lbf/templates/components/modal/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OSnhjPFf",
    "block": "[[[10,0],[14,0,\"modal fade l-modal\"],[14,1,\"abc\"],[14,\"tabindex\",\"-1\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"dropshadow\"],[24,\"role\",\"button\"],[4,[38,0],[[30,0],\"dropshadowClick\"],null],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-dialog modal-lg\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-content l-modal__content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"modal-header l-modal__header\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"h4 modal-title\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,\"i\"],[14,0,\"icon\"],[12],[1,[28,[35,2],null,[[\"name\"],[[30,1]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[1,[30,2]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-sm btn-secondary btn--transparent btn--icon\"],[14,\"title\",\"Luk vindue\"],[14,\"aria-label\",\"Luk vindue\"],[14,\"data-bs-dismiss\",\"modal\"],[14,4,\"button\"],[12],[1,[28,[35,2],null,[[\"name\"],[\"close\"]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"modal-body l-modal__body p-0\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row g-0\"],[12],[1,\"\\n          \"],[18,3,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@title\",\"&default\"],false,[\"action\",\"if\",\"icons\",\"yield\"]]",
    "moduleName": "lbf/templates/components/modal/modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});