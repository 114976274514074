define("lbf/components/icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-icon'],
    classNameBindings: ['sizeClassName'],
    sizeClassName: Ember.computed('size', function () {
      if (this.size == "n2") {
        return 'c-icon--size-n2';
      } else if (this.size == "n1") {
        return 'c-icon--size-n1';
      } else if (this.size == "1") {
        return 'c-icon--size-1';
      } else if (this.size == "2") {
        return 'c-icon--size-2';
      } else if (this.size == "3") {
        return 'c-icon--size-3';
      }

      return '';
    })
  });

  _exports.default = _default;
});