define("lbf/components/accordion/accordion-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-accordion-header h2'],
    tagName: 'div',
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.set('expanded', !this.expanded);

        if (this.change) {
          this.change(this.expanded);
        }
      }
    }
  });

  _exports.default = _default;
});