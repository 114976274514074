define("lbf/components/poi/selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    poi: Ember.inject.service('poi'),
    tagName: 'li',
    classNames: ['form-check', 'form-check--btncheckbox', 'form-check--checkcolor'],
    selected: Ember.computed('state.activePoiLayers.[]', 'layer.id', function () {
      return this.state.activePoiLayers.includes(this.layer.id);
    }),
    updateValue: function updateValue() {
      if (this.state.activePoiLayers.includes(this.layer.id)) {
        this.state.activePoiLayers.removeObject(this.layer.id);
      } else {
        this.state.activePoiLayers.addObject(this.layer.id);
      } //Added to trigger change event. Array types doesn't trigger change events


      this.state.save();
      this.poi.show(this.state.activePoiLayers);
    }
  });

  _exports.default = _default;
});