define("lbf/helpers/safehtml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function safehtml(string) {
    return Ember.String.htmlSafe(string);
  }

  var _default = Ember.Helper.helper(safehtml);

  _exports.default = _default;
});