define("lbf/templates/components/buildsection-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOZeatoM",
    "block": "[[[8,[39,0],[[24,0,\"border-0\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"text-primary\"],[24,\"title\",\"Afdeling\"]],[[\"@type\",\"@color\"],[\"icon\",\"tint-primary\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"name\"],[\"byggeafsnit\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"text-dark d-flex justify-content-between\"]],null,[[\"default\"],[[[[1,[33,5,[\"navn\"]]],[1,\" \"],[10,\"small\"],[14,0,\"c-data-lbfnummer\"],[12],[10,1],[14,0,\"visually-hidden\"],[12],[1,\"Byggeafsnitsnr.: \"],[13],[1,[33,5,[\"afdelingByggeriNummer\"]]],[13]],[]]]]],[1,\"\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,[33,5,[\"beliggenhed\"]]]],[]]]]],[1,\"\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,[33,5,[\"kommune\",\"navn\"]]]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"list/list-item\",\"avatar\",\"icons\",\"list/list-item-content\",\"list/list-item-title\",\"byggeri\",\"list/list-item-subtitle\"]]",
    "moduleName": "lbf/templates/components/buildsection-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});