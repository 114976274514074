define("lbf/templates/components/septima-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70yv9G21",
    "block": "[[],[],false,[]]",
    "moduleName": "lbf/templates/components/septima-search.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});