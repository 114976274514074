define("lbf/templates/components/poi/container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3CU4Jv/Z",
    "block": "[[[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"poi\",\"config\"]]],null]],null],null,[[[1,\"  \"],[10,1],[14,0,\"h6 fw-semibold\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"ps-0\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"layers\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@layer\",\"@state\",\"@class\"],[[30,2],[30,0,[\"state\"]],[29,[\"poi-\",[30,2,[\"icon\"]]]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]],null]],[\"group\",\"layer\"],false,[\"each\",\"-track-array\",\"poi/selector\"]]",
    "moduleName": "lbf/templates/components/poi/container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});