define("lbf/config/sagstyper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sagstyper = void 0;
  var sagstyper = [{
    id: 1,
    name: 'Renoveringsstoette',
    title: 'Renoveringsstøtte',
    className: 'case-1',
    color: '#00b3ff',
    milestones: [{
      name: '1',
      title: 'Ansøgning'
    }, {
      name: '2',
      title: 'Besigtigelse gennemført'
    }, {
      name: '3',
      title: 'Indstilling udarbejdet'
    }, {
      name: '4',
      title: 'Skema A'
    }, {
      name: '5',
      title: 'Skema B'
    }, {
      name: '6',
      title: 'Afsluttet'
    }]
  }, {
    id: 2,
    name: 'Infrastruktur',
    title: 'Infrastruktur',
    className: 'case-2',
    color: '#AC00FF',
    milestones: [{
      name: '1',
      title: 'Ansøgning'
    }, {
      name: '2',
      title: 'Besigtigelse gennemført'
    }, {
      name: '3',
      title: 'Indstilling udarbejdet'
    }, {
      name: '4',
      title: 'Skema A'
    }, {
      name: '5',
      title: 'Skema B'
    }, {
      name: '6',
      title: 'Afsluttet'
    }]
  }, {
    id: 3,
    name: 'Kapitaltilfoersel',
    title: 'Kapitaltilførsel',
    className: 'case-3',
    color: '#0035b5',
    milestones: [{
      name: '1',
      title: 'Ansøgning'
    }, {
      name: '2',
      title: 'Besigtigelse gennemført'
    }, {
      name: '3',
      title: 'Indstilling udarbejdet'
    }, {
      name: '4',
      title: 'Skema A'
    }, {
      name: '5',
      title: 'Skema B'
    }, {
      name: '6',
      title: 'Afsluttet'
    }]
  }, {
    id: 4,
    name: 'Huslejestoette',
    title: 'Huslejestøtte',
    className: 'case-4',
    color: '#FF00C7',
    milestones: []
  }, {
    id: 5,
    name: 'Boligsocialindsats',
    title: 'Boligsocial indsats',
    className: 'case-5',
    color: '#FF9B1F',
    milestones: [{
      name: '1',
      title: 'Ansøgning'
    }, {
      name: '2',
      title: 'Besigtigelse gennemført'
    }, {
      name: '3',
      title: 'Indstilling udarbejdet'
    }, {
      name: '4',
      title: 'Skema A'
    }, {
      name: '5',
      title: 'Skema B'
    }, {
      name: '6',
      title: 'Afsluttet'
    }]
  }, {
    id: 6,
    name: 'Regnskabsgennemgang',
    title: 'Regnskabsgennemgang',
    className: 'case-6',
    color: '#6be600',
    milestones: []
  }];
  _exports.sagstyper = sagstyper;
});