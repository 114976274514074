define("lbf/adapters/state", ["exports", "@ember-data/adapter", "lbf/utils/bruger"], function (_exports, _adapter, _bruger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = this.serialize(snapshot, {
        includeId: true
      });
      (0, _bruger.saveState)(data);
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run(null, resolve, data);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = this.serialize(snapshot, {
        includeId: true
      });
      (0, _bruger.saveState)(data);
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run(null, resolve, data);
      });
    }
  });

  _exports.default = _default;
});