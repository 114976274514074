define("lbf/components/legend/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq config.type 'box')}}
    <li><span class="signatur-box {{config.className}}"><i class="fill"></i></span><span>{{config.title}}</span></li>
  {{else if (eq config.type 'line')}}
    <li><span class="signatur-line {{config.className}}"></span><span>{{config.title}}</span></li>
  {{else if (eq config.type 'icon')}}
    <li>{{icons name=config.icon}}<span>{{config.title}}</span></li>
  {{else if (eq config.type 'group')}}
    <Legend::Group @config={{@config}}/>
  {{/if}}
  
  */
  {
    "id": "IiB6d5yb",
    "block": "[[[41,[28,[37,1],[[33,2,[\"type\"]],\"box\"],null],[[[1,\"  \"],[10,\"li\"],[12],[10,1],[15,0,[29,[\"signatur-box \",[33,2,[\"className\"]]]]],[12],[10,\"i\"],[14,0,\"fill\"],[12],[13],[13],[10,1],[12],[1,[33,2,[\"title\"]]],[13],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[33,2,[\"type\"]],\"line\"],null],[[[1,\"  \"],[10,\"li\"],[12],[10,1],[15,0,[29,[\"signatur-line \",[33,2,[\"className\"]]]]],[12],[13],[10,1],[12],[1,[33,2,[\"title\"]]],[13],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[33,2,[\"type\"]],\"icon\"],null],[[[1,\"  \"],[10,\"li\"],[12],[1,[28,[35,3],null,[[\"name\"],[[33,2,[\"icon\"]]]]]],[10,1],[12],[1,[33,2,[\"title\"]]],[13],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[33,2,[\"type\"]],\"group\"],null],[[[1,\"  \"],[8,[39,4],null,[[\"@config\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[]]]],[\"@config\"],false,[\"if\",\"eq\",\"config\",\"icons\",\"legend/group\"]]",
    "moduleName": "lbf/components/legend/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagname: ''
  }));

  _exports.default = _default;
});