define("lbf/config/chartoptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartOptions = void 0;
  // const fontColor = '#72889A'
  var fontColor = '#595c5f';
  var fontSize = 12;
  var fontFamily = "'Montserrat', 'Arial', sans-serif";
  var fontStyle = 500;
  var chartOptions = {
    legend: {
      display: true,
      labels: {
        fontColor: fontColor,
        fontSize: fontSize,
        fontFamily: fontFamily,
        fontStyle: fontStyle,
        boxWidth: 14
      }
    },
    tooltips: {
      titleFontSize: fontSize,
      bodyFontSize: fontSize,
      bodyFontStyle: fontStyle,
      titleFontFamily: fontFamily,
      bodyFontFamily: fontFamily,
      mode: 'nearest'
    },
    responsive: true,
    scales: {
      xAxes: [{
        fontColor: fontColor,
        fontSize: fontSize,
        fontFamily: fontFamily,
        stacked: true,
        barPercentage: 0.4,
        ticks: {
          fontColor: fontColor,
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontStyle: fontStyle
        },
        gridLines: {
          drawBorder: false,
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        barPercentage: 0.4,
        ticks: {
          fontColor: fontColor,
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontStyle: fontStyle,
          precision: 0,
          beginAtZero: true
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: '#C1CCD5',
          color: '#DBE4E8'
        }
      }]
    }
  };
  _exports.chartOptions = chartOptions;
});