define("lbf/components/accordion/accordion-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-accordion-collapse', 'px-3', 'pb-4'] // der skal noget sr-only på denne der bliver toggled om den er åben eller lukket

  });

  _exports.default = _default;
});