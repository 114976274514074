define("lbf/templates/components/loading-afdelinger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dPfMIlNn",
    "block": "[[[1,\"\\n\"],[8,[39,0],[[24,0,\"pt-0\"]],[[\"@type\"],[\"card\"]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[28,[37,2],[[30,0,[\"afdelinger\",\"length\"]],0],null],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"afdelinger\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],null,[[\"@lines\"],[\"2\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n              \"],[10,0],[14,0,\"c-skeleton-loader__heading\"],[12],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n              \"],[10,0],[14,0,\"c-skeleton-loader__paragraph w-75\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"c-skeleton-loader__text mt-1 mb-0\"],[12],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"list/list\",\"if\",\"gt\",\"each\",\"-track-array\",\"list/list-item\",\"list/list-item-content\",\"list/list-item-title\",\"list/list-item-subtitle\"]]",
    "moduleName": "lbf/templates/components/loading-afdelinger.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});