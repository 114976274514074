define("lbf/router", ["exports", "lbf/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('overblik', function () {
      this.route('favoritter', {
        path: '/udvalgte'
      });
      this.route('afdelinger');
      this.route('afdeling', {
        path: '/afdelinger/:lbfnummer'
      }, function () {
        this.route('bygninger');
        this.route('bygning', {
          path: '/bygninger/:bygningid'
        });
        this.route('om', {
          path: '/'
        });
        this.route('selvbetjening');
      });
    });
    this.route('noaccess');
  });
  var _default = Router;
  _exports.default = _default;
});